import { createReducer } from '@reduxjs/toolkit'
import { walletConnector } from './actions'

export interface WalletState {
  walletConnector: any
}

export const initialState: WalletState = {
  walletConnector: localStorage.getItem('walletConnector')
}

export default createReducer(initialState, (builder) =>
  builder.addCase(walletConnector, (state, action) => {
    state.walletConnector = action.payload
  })
)

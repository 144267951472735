import airdropNetwork from 'data/airdropNetwork'
import Web3 from 'web3'

const web3 = new Web3()

const useNetwork = () => {
  const addNetwork = async (chainId: any, onSuccess: any) => {
    try {
      if (!window.ethereum) return
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          { ...airdropNetwork[chainId], chainId: web3.utils.toHex(chainId) }
        ]
      })
      onSuccess && onSuccess()
    } catch (error) {
      console.error(error)
    }
  }

  const switchNetwork = async (
    chainId: any,
    onStart?: () => void,
    onSuccess?: () => void,
    onError?: (err: any) => void
  ) => {
    if (!window.ethereum) return
    onStart && onStart()
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3.utils.toHex(chainId) }]
      })
      onSuccess && onSuccess()
    } catch (err: any) {
      console.error(err)
      onError && onError(err)
      if (err?.code === 4902) {
        addNetwork(
          chainId,
          async () => await switchNetwork(chainId, onStart, onSuccess, onError)
        )
      }
    }
  }
  return { switchNetwork }
}

export default useNetwork

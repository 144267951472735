const ProgressBar = ({ percent = 0 }) => (
  <div className='relative h-3 w-full overflow-hidden rounded-lg bg-neutral-700'>
    <div
      className='absolute top-0 left-0 z-10 h-full rounded-lg bg-redorange
      transition-all duration-500 ease-in'
      style={{ width: `${percent}%` }}
    ></div>
  </div>
)

export default ProgressBar

import { notification as notiAntd } from 'antd'

type TNotificationType = 'success' | 'info' | 'warning' | 'error' | 'open'

const openNotification = (
	type: TNotificationType,
	message: string,
	description?: any,
	key?: string,
	duration?: number
) => {
	notiAntd[type]({
		key,
		message,
		description: description ? description : '',
		style: {
			top: '50px',
			backgroundColor: '#161C24',
			color: '#fff',
			border: '1px solid rgba(114, 124, 135, 0.3)',
			borderRadius: '5px'
		},
		duration: duration === 0 ? 0 : 4.5
	})
}

const notification = {
	success: (message = '', description = '', key = '') =>
		openNotification('success', message, description, key),
	error: (message = '', description = '', key = '') =>
		openNotification('error', message, description, key),
	info: (message = '', description = '', key = '') =>
		openNotification('info', message, description, key),
	warning: (message = '', description = '', key = '') =>
		openNotification('warning', message, description, key),
	open: (message = '', description: any, key = '') => {
		openNotification('open', message, description, key, 0)
	}
}

export default notification

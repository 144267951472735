import { TransactionResponse } from '@ethersproject/providers'
import { Steps } from 'antd'
import { FailedTransactionModal, TransactionStepModal } from 'components/Modal'
import useToggle from 'hooks/useToggle'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { useActiveWeb3React } from 'hooks/web3'
import { ReactNode, useState } from 'react'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { ButtonPrimary } from '../HandleButton'
import { getDecimalAmount } from 'utils/formatBalance'
import { BigNumber } from 'bignumber.js'

const { Step } = Steps
export default function InteractContractButton({
  children,
  contract,
  data,
  methodName,
  callback,
  className = '',
  disable = false,
  tooltip = '',
  size,
  value = 0
}: {
  className?: string
  children: ReactNode
  contract?: any
  data?: any
  methodName?: string
  disable?: boolean
  tooltip?: React.ReactNode | string
  size?: SizeType
  callback?: (log: any) => void
  value?: any
}) {
  const { account } = useActiveWeb3React()

  const [txStatus, setTxStatus] = useState<any>('process')
  const [isTransfer, setIsTransfer] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    visible: visibleFModal,
    enable: enableFModal,
    disable: disableFModal
  } = useToggle()

  const onError = (message: any) => {
    enableFModal()
    setErrorMessage(message)
  }

  const onInteraction = async () => {
    setIsTransfer(true)
    setCurrentStep(1)
    try {
      if (!contract || !account || !methodName || !callback) return
      const method: (...args: any) => Promise<TransactionResponse> =
        contract[methodName]
      const args: Array<string | string[] | number> = data
      console.log('contract', contract)
      console.log('args', args)
      const valueETH = getDecimalAmount(new BigNumber(value), 18).toString()
      await contract.estimateGas[methodName](...args, {
        value: valueETH
      })
        .then((estimatedGasLimit: any) => {
          method(...args, {
            from: account,
            gasLimit: calculateGasMargin(estimatedGasLimit),
            value: valueETH
          })
            .then(async (response: TransactionResponse) => {
              setCurrentStep(2)
              const txFull = await response.wait()
              setCurrentStep(3)
              if (txFull.status === 0) {
                setTxStatus('error')
                setTimeout(() => {
                  setIsTransfer(false)
                }, 2000)
                onError('Transaction failed. Please try again.')
                return
              }
              setCurrentStep(4)
              const logs = txFull.logs
              const this_contract_log = logs.find(
                (log: any) => log.address === contract.address
              )
              setCurrentStep(5)
              setTimeout(() => {
                callback(this_contract_log)
                setTimeout(() => {
                  setIsTransfer(false)
                }, 100)
              }, 500)
            })
            .catch((error: any) => {
              console.error('Error', error)
              onError(error.data ? error.data.message : error.message)
              setIsTransfer(false)
            })
        })
        .catch((error: any) => {
          onError(error.data ? error.data.message : error.message)
          setIsTransfer(false)
        })
    } catch (error: any) {
      onError(error.data ? error.data.message : error.message)
      setIsTransfer(false)
    }
  }

  return (
    <>
      <ButtonPrimary
        className={className}
        size={size}
        disable={isTransfer || disable}
        loading={isTransfer}
        onClick={onInteraction}
        tooltip={tooltip}
      >
        {children}
      </ButtonPrimary>
      <TransactionStepModal visible={isTransfer} step={currentStep} />
      <FailedTransactionModal
        visible={visibleFModal}
        onClose={disableFModal}
        message={errorMessage}
      />
    </>
  )
}

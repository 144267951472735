import authApi from 'api/authApi'
import userApi from 'api/userApi'
import { useActiveWeb3React } from 'hooks/web3'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { AppState } from 'state'
import { useAppSelector } from 'state/hooks'
import { useLoginRofiAccount } from 'state/rofi/hooks'
import { actionFetchAllServiceStatus } from 'state/service/action'

type Props = {
  children: React.ReactNode
}
type TAction = {
  signin?: any
}

const authActionInit: TAction = {
  signin: () => {}
}

const AuthContext = createContext({
  authState: {
    isConnectedWallet: undefined
  },
  authAction: authActionInit
})

const AuthProvider = ({ children }: Props): JSX.Element => {
  const { account } = useActiveWeb3React()
  const { rofiAccount } = useAppSelector((state: AppState) => state.rofi)
  const loginRofiAccount = useLoginRofiAccount()

  const dispatch = useDispatch()

  const [isConnectedWallet, setIsConnectedWallet] = useState<any>(false)

  const fetchAccountInfo = useCallback(async () => {
    let token_api = localStorage.getItem('token_api')

    if (!token_api) {
      return
    }

    try {
      const result = await userApi.getInfo()
      if (result.code !== 0) {
        // notification.error('Error', decode_API_code(result.code))
        return
      }
      const rofi_id = result.data
      loginRofiAccount(rofi_id)
    } catch (error) {
      // notification.error('Error', 'Please sign in again')
      console.error(error)
    }
  }, [loginRofiAccount])

  const signin = async (data: any): Promise<void> => {
    try {
      await authApi.authentication(data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    setIsConnectedWallet(
      rofiAccount
        ? rofiAccount.wallet !== ''
          ? rofiAccount.wallet
          : undefined
        : account
    )
  }, [account, rofiAccount])

  useEffect(() => {
    fetchAccountInfo()
  }, [fetchAccountInfo])

  useEffect(() => {
    dispatch(actionFetchAllServiceStatus())
  }, [dispatch])

  return (
    <AuthContext.Provider
      value={{
        authState: { isConnectedWallet },
        authAction: { signin }
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuthContext = () => useContext(AuthContext)

export { useAuthContext, AuthProvider }

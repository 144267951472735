// export const GRAPH_API_LOTTERY = 'https://api.thegraph.com/subgraphs/name/pancakeswap/lottery'
// export const GRAPH_API_ORDER = 'https://api.studio.thegraph.com/query/6582/heromarkettest/v0.0.7'
// export const GRAPH_API_ORDER = 'http://192.168.21.155:4000/heromarket'
// export const HERO_MARKET_SERVER = 'http://192.168.21.155:4000'
export const GRAPH_API_ORDER = 'http://localhost:4000/heromarket'
export const RPC_ENDPOINT_TESTNET = 'https://data-seed-prebsc-2-s1.binance.org:8545/'
export const RPC_ENDPOINT_MAINNET = 'https://bsc-dataseed1.defibit.io/'
export const HERO_REWARD = 'https://herofi-reward.bravechain.net/api/reward'
export const TUTORIAL_URL = 'https://herofi.io/instruction'
export const PROOF_LEVEL = 'https://herofi-level-test.bravechain.net/api/proof'
export const GET_REWARDS = 'https://herofi-reward-test.bravechain.net/api/reward'
export const GET_REWARDS_FROM_19 = 'https://herofi-reward.bravechain.net/api/reward'
export const GET_PROOF_SINGLE_REWARD = 'https://herofi-reward-test.bravechain.net/api/reward/get_proof'
export const GET_PROOF_ALL_REWARDS = 'https://herofi-reward-test.bravechain.net/api/reward/get_proofs'
export const GHFI_CONVERT_REQUEST = 'https://hfi.bravechain.net/api/request'
export const GHFI_WALLET = 'https://hfi.bravechain.net/api/wallet'
export const GLGFI_CONVERT_REQUEST = 'https://lgfi-claim.bravechain.net/api/request'
export const GLGFI_WALLET = 'https://lgfi-claim.bravechain.net/api/wallet'
export const LGFI_PROOF_LEVEL = 'https://lg-level.bravechain.net/api/proof'
//PEFI
export const HERO_MARKET_SERVER = 'https://app.plantempires.io'
export const ROFI_AUTH_URL = 'https://app.rofi.io/#/auth'
export const GPEFI_CONVERT_REQUEST = 'https://pefi-claim.bravechain.net/api/request'
export const GPEFI_WALLET = 'https://pefi-claim.bravechain.net/api/wallet'
export const ORBS_PROOF = 'https://pefi-proof.bravechain.net/api/orbs'
export const PEFI_HOLY = 'https://holy.bravechain.net/api/holy'
export const HOW_TO_BUY = 'https://plantempires.io/how-to-purchase-nft-items-on-plant-empires-marketplace.html'
export const PEFI_API = 'https://pefi-claim.bravechain.net'

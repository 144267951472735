import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const UNI_ADDRESS: AddressMap = constructSameAddressMap(
  '0xd9d86e5d2cbb3637e078119745f737e21e4e8ab1'
)

export const MULTICALL2_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x5ba1e12693dc8f9c48aad8770482f4739beed696'),
  [SupportedChainId.BSC]: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B'
}
const BSC_V2_FACTORY_ADDRESS = '0x0293e286319Ec83e517B4F22427aE4A4E260aA56'
export const V2_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(
  BSC_V2_FACTORY_ADDRESS
)
export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap(
  '0x910d0795dcDE4052BB0B6bC5fc61506D30432430'
)

// most current governance contract address should always be the 0 index
export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap(
  '0x1a9C8182C09F50C8318d769245beA52c32BE35BC'
)

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.BSC]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
}

export const BUSD_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  [SupportedChainId.ARBITRUM_ONE]: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
}

export const ROFI_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]:
    process.env.REACT_APP_ROFI_TOKEN_ADDRESS ||
    '0x3244B3B6030f374bAFA5F8f80Ec2F06aAf104B64'
}

// IDOL WORLD
export const RG02_TOKEN_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]:
    process.env.REACT_APP_RG02_TOKEN_ADDRESS ||
    '0xcA7124398CF5ECd13522b3019c244279F4D907DB'
}

export const RG02_NFT_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]:
    process.env.REACT_APP_RG02_NFT_ADDRESS ||
    '0x0c2955A0E514e8b8af6672a234b0245D4F49e6bf'
}

export const RG02_BOX_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]:
    process.env.REACT_APP_RG02_BOX_ADDRESS ||
    '0xf435403d39Bf2E41e6C03BfBd5BdBadbe425D688'
}

export const RG02_TRANSFER_NFT_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]:
    process.env.REACT_APP_RG02_TRANSFER_NFT_ADDRESS ||
    '0xeb5a56ba194349f926Be3c3Bf60f633A9af1d0F3'
}

export const RG02_MINT_NFT_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]:
    process.env.REACT_APP_RG02_MINT_NFT_ADDRESS ||
    '0xD80507186a7a5e2d11B914ba9Bcb1b3fFbc8B990'
}

export const TRANSFER_TOKEN_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]:
    process.env.REACT_APP_TRANSFER_TOKEN_ADDRESS ||
    '0x7a17DA01cDBB14e3AbEE3BaEBD1394f75eD14097'
}

export const RG02_NFT_MARKET_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]:
    process.env.REACT_APP_RG02_NFT_MARKET_ADDRESS ||
    '0x6005B83Ff46341f2d3b1935Cf6BE420448d87F34'
}

export const RG02_NFT_SHOP_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]:
    process.env.REACT_APP_RG02_NFT_SHOP_ADDRESS ||
    '0x55CAa882AAB2c4e0D8eCddde05Da5E164Db9456d'
}

export const RG02_IDL_SHOP_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]:
    process.env.REACT_APP_RG02_IDL_SHOP_ADDRESS ||
    '0xddEF19bB503D02A1BDa5490Bf18eac17F538872c'
}

export const RG02_NFT_AIRDROP_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]:
    process.env.REACT_APP_RG02_NFT_AIRDROP_ADDRESS ||
    '0xd859da42C0C05302914073F43B8F23Fc69A79983'
}

export const RG02_UNLOCKED_STAKE_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]:
    process.env.REACT_APP_RG02_UNLOCKED_STAKE_ADDRESS ||
    '0x2D28864c9e1c5B8511b2f4540d9155D4e444A889'
}

export const RG02_LOCKED_STAKE_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]:
    process.env.REACT_APP_RG02_LOCKED_STAKE_ADDRESS ||
    '0xEFcD8260Dbae99A7d28B16Fb9A59f6d1cC955A15'
}

export const RG02_UNLOCKED_POOL_REWARD_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]:
    process.env.REACT_APP_RG02_UNLOCKED_POOL_REWARD_ADDRESS ||
    '0xfC0f0F990cCec7e5270ED447760ea096875062b0'
}

export const RG02_LOCKED_POOL_REWARD_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]:
    process.env.REACT_APP_RG02_LOCKED_POOL_REWARD_ADDRESS ||
    '0xfC0f0F990cCec7e5270ED447760ea096875062b0'
}

export const USDT_ADDRESS: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
  [SupportedChainId.BSC]: '0x55d398326f99059fF775485246999027B3197955',
  [SupportedChainId.zkSYNC_ERA]: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
}
export const WETH_ADDRESS: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'
}

export const AIRDROP_POOL_V1_ADDRESS: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]:
    '0xDFFe2DbC74cb9F41f32D79e0325E9562C044f1fE',
  [SupportedChainId.BSC]:
    '0xE0e9D75FC139951D6354CefEF2A62631cD4ebC1e',
  [SupportedChainId.zkSYNC_ERA]:
    '0xdbA7F8D1D7d6d09A72EFfd68Db8Fa218E75cFB61',
}

export const FAIR_AUCTION_ADDRESS: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]:
    '0x2aFA99fa7bE2bB869B1DbA612CF9a07e6b82749b'
}


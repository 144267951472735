import { FC, forwardRef } from 'react'

const CustomButton: FC<any> = forwardRef(
  ({ className, children, disable, onClick }, ref: any) => {
    return (
      <button
        ref={ref}
        className={`btn-gradient-1 w-full p-2.5 text-white disabled:cursor-not-allowed disabled:opacity-75
        ${className}`}
        disabled={disable}
        onClick={() => onClick && onClick()}
      >
        <div className='relative z-[2]'>{children}</div>
      </button>
    )
  }
)

export default CustomButton

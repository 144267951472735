import { ROFI_TOKEN } from 'constants/endpointConfigs'
import axiosClient from './http-common'

const walletApi = {
	getAllBalances: (): Promise<any> => {
		const url = ROFI_TOKEN.BALANCE_ALL
		return axiosClient.get(url)
	},
	getBalance: (address: string, symbol: string): Promise<any> => {
		const url = `${ROFI_TOKEN.GETBALANCEBYSYMBOL(address)}?symbol=${symbol}`
		return axiosClient.get(url)
	},
	getTransaction: (page = 1, symbol: string): Promise<any> => {
		const url = ROFI_TOKEN.TRANSACTIONS
		const params = symbol ? { page, symbol } : { page }
		return axiosClient.get(url, { params })
	},
	getAllTransaction: (token: string): Promise<any> => {
		const url = `/tokens/${token}/transactions`
		return axiosClient.get(url)
	},
	withdrawToken: (address: string, data: any): Promise<any> => {
		const url = ROFI_TOKEN.WITHDRAW_TOKEN(address)
		return axiosClient.post(url, data)
	}
}

export default walletApi

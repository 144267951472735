import { ButtonPrimary } from 'components/Buttons/HandleButton'
import DefaultModal from './DefaultModal'

interface ISuccessTrans {
  visible: boolean
  message: any
  onClose: () => void
}

const FailedTransactionModal = ({
  visible,
  message,
  onClose
}: ISuccessTrans) => {
  return (
    <DefaultModal
      title={'Transaction failed'}
      visible={visible}
      className='!w-[440px]'
      allowClose={false}
    >
      <div className='mx-auto w-full space-y-6'>
        <p className='text-center text-red-500'>{message}</p>
        <ButtonPrimary className='btn-gray' onClick={onClose}>
          Close
        </ButtonPrimary>
      </div>
    </DefaultModal>
  )
}

export default FailedTransactionModal

import { usePublicContext } from 'contexts/PublicContext'
import queryString from 'query-string'
import { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

const CallbackPage = () => {
  const history = useHistory()
  const location = useLocation()
  const { redirectRoute } = useParams() as any

  const { accessCallbackParams } = usePublicContext() as any
  useEffect(() => {
    const searchData = queryString.parse(
      location.search.slice(1, location.search.length)
    )

    if (Object.keys(searchData).length > 0) {
      Object.keys(searchData).forEach((x) => {
        accessCallbackParams(x, searchData[x])
      })
    }
  }, [accessCallbackParams, location.search])

  useEffect(() => {
    const sto = setTimeout(() => {
      history.push(`/${redirectRoute}`, { scrollTo: 'form' })
    }, 1500)

    return () => {
      clearTimeout(sto)
    }
  }, [history, redirectRoute])

  return <div className='text-center'>Waiting...</div>
}

export default CallbackPage

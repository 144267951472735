import { useState } from 'react'
import useEventListener from './useEventListener'

const isClient = !!(
	typeof window !== 'undefined' &&
	window.document &&
	window.document.createElement
)

function useWindowSize(
	initialWidth = undefined,
	initialHeight = undefined
): object {
	const [windowSize, setWindowSize] = useState({
		width: isClient ? window.innerWidth : initialWidth,
		height: isClient ? window.innerHeight : initialHeight
	})

	useEventListener('resize', () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight
		})
	})

	return windowSize
}

export default useWindowSize

import {
  ArrowsRightLeftIcon,
  BuildingStorefrontIcon,
  RocketLaunchIcon,
  Square3Stack3DIcon
} from '@heroicons/react/24/solid'
import { ReactNode, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import tw from 'twin.macro'

const HeaderFrame = styled.div`
  ${() => tw`border-t border-border01 backdrop-blur-lg`}
  width: 100%;
  bottom: 0;
  position: relative;
  z-index: 21;
  position: relative;
  height: max-content;
  padding: 10px 15px;
  background-image: radial-gradient(#212b3600 1.2px, #333333 0.5px);
  background-size: 5px 5px;
`

const MobileNavbar = () => {
  //? Router
  const { pathname } = useLocation()

  const getActiveByPath = useCallback(
    (pathActive) => pathname.includes(pathActive),
    [pathname]
  )

  return (
    <HeaderFrame>
      <div className='grid grid-cols-3 justify-center'>
        <MobileNavbarItem
          icon={<BuildingStorefrontIcon className='h-full w-full' />}
          name='Airdrop'
          active={getActiveByPath('airdrop')}
          to='/airdrop'
        />
        <MobileNavbarItem
          icon={<RocketLaunchIcon className='h-full w-full' />}
          name='Launchpad'
          active={getActiveByPath('launchpad')}
          to='/#'
          isComingSoon={'Soon'}
        />
        {/* <MobileNavbarItem
          icon={<ArrowsRightLeftIcon className='h-full w-full' />}
          name='Migrate'
          active={getActiveByPath('migrate')}
          to='/migrate'
        /> */}
        <OutMobileNavbarItem
          icon={<Square3Stack3DIcon className='h-full w-full' />}
          name={'Games'}
          active={false}
          to={'https://games.eraprotocol.io'}
          isComingSoon={'New'}
        />
        {/* <MobileNavbarItem
          icon={<GiftIcon className='h-full w-full' />}
          name='Event'
          active={getActiveByPath('launchpad')}
          to='/event'
        /> */}
      </div>
    </HeaderFrame>
  )
}

const MobileNavbarItem = ({
  icon,
  name,
  isComingSoon = undefined,
  active = false,
  to = ''
}: {
  icon: ReactNode
  name: string
  isComingSoon?: any
  active: boolean
  to: string
}) => {
  return (
    <Link
      to={to}
      className={`${
        active ? 'font-bold text-primary' : 'font-normal text-neutral-50'
      } relative flex cursor-pointer flex-col 
      items-center transition duration-150 ease-in-out hover:text-primary`}
    >
      <div className='h-7 w-7'>{icon}</div>
      <p className=''>{name}</p>
      {isComingSoon && (
        <div className='absolute right-2 -top-2 text-xs text-redorange'>
          {isComingSoon}
        </div>
      )}
    </Link>
  )
}

const OutMobileNavbarItem = ({
  icon,
  name,
  isComingSoon = false,
  active = false,
  to = ''
}: {
  icon: ReactNode
  name: string
  isComingSoon?: any
  active: boolean
  to: string
}) => {
  return (
    <a
      href={to}
      className={`${
        active ? 'font-bold text-primary' : 'font-normal text-neutral-50'
      } relative flex cursor-pointer flex-col 
      items-center transition duration-150 ease-in-out hover:text-primary`}
    >
      <div className='h-7 w-7'>{icon}</div>
      <p className=''>{name}</p>
      {isComingSoon && (
        <div className='absolute right-2 -top-2 text-xs text-redorange'>
          {isComingSoon}
        </div>
      )}
    </a>
  )
}

export default MobileNavbar

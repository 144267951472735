import { createSlice } from '@reduxjs/toolkit'
import { fetchAllServiceStatus, returnToInitialService } from './action'

const initialState: {
	loading: boolean
	error: any
	status: Object
} = {
	loading: false,
	error: null,
	status: {}
}

export const serviceSlice = createSlice({
	name: 'service',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(returnToInitialService, state => {
			state.status = initialState.status
		})

		builder
			.addCase(fetchAllServiceStatus.pending, state => {
				state.loading = true
			})
			.addCase(fetchAllServiceStatus.fulfilled, (state, { payload }) => {
				state.loading = false
				const newStatus: any = {}
				payload.forEach((item: any) => {
					newStatus[item.name] = item.status
				})
				state.status = newStatus
			})
			.addCase(fetchAllServiceStatus.rejected, (state, { error }) => {
				state.loading = false
				state.error = error
			})
	}
})

export const serviceReducer = serviceSlice.reducer

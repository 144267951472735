import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'
import serviceApi from 'api/serviceApi'

type ArgsFetchBalance = {
	address: string
	symbol: string
}

const voidInit = () => {}

export const returnToInitialService = createAction('services/returnToInitial')

export const fetchAllServiceStatus = createAsyncThunk(
	'services/getAllServiceStatus',
	async () => {
		const result = await serviceApi.getAllServiceStatus()
		return result.data
	}
)

export const actionFetchAllServiceStatus = (
	onSuccess = voidInit,
	onError = voidInit
) => {
	return async (dispatch: any) => {
		try {
			const resultAction = await dispatch(fetchAllServiceStatus())
			const originalPromiseResult = unwrapResult(resultAction)
			onSuccess()
		} catch (rejectedValueOrSerializedError) {
			onError()
		}
	}
}

import { useEffect, useRef } from 'react'

const isClient = !!(
	typeof window !== 'undefined' &&
	window.document &&
	window.document.createElement
)

function useEventListener(
	eventName = '',
	handler: any,
	element = isClient ? window : undefined
) {
	const savedHandler = useRef<any>()

	useEffect(() => {
		savedHandler.current = handler
	}, [handler])

	useEffect(() => {
		// Make sure element supports addEventListener
		// On
		const isSupported = element && element.addEventListener
		if (!isSupported) return

		const eventListener = (event: any) => savedHandler.current(event)
		element?.addEventListener(eventName, eventListener)

		return () => {
			element && element.removeEventListener(eventName, eventListener)
		}
	}, [eventName, element])
}

export default useEventListener

import axios from 'axios'
import { ROFI_ACCOUNT } from 'constants/endpointConfigs'
import queryString from 'query-string'
import TokenService from 'services/token'

const axiosClient = axios.create({
  headers: {
    'content-type': 'application/json'
  },
  paramsSerializer: (params) => queryString.stringify(params),
  timeout: 30000
})

axiosClient.interceptors.request.use(
  (config) => {
    // Handle token
    const token = localStorage.getItem('token_api')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data
    }

    return response
  },
  async (error) => {
    //Handle error
    let originalConfig = error.config

    if (error.response) {
      // Access Token was expired
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        try {
          const result: {
            accessToken: string
            refreshToken: string
            expireIn: string
          } = await axiosClient.post(
            ROFI_ACCOUNT.REFRESH_TOKEN,
            {
              refreshToken: TokenService.getLocalRefreshToken()
            },
            originalConfig
          )

          const { accessToken, refreshToken, expireIn }: any = result
          TokenService.updateLocalAccessToken(accessToken)
          TokenService.setLocalRefreshToken(refreshToken)
          TokenService.setExpired(expireIn)

          return axiosClient(originalConfig)
        } catch (error) {
          return Promise.reject(error)
        }
      }
    }

    return Promise.reject(error.response.data)
  }
)

export default axiosClient

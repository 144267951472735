import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

const PublicContext = createContext({}) as any

const PublicProvider: FC = ({ children }): JSX.Element => {
  const [callbackParams, setCallbackParams] = useState({}) as any

  const accessCallbackParams: Function = useCallback((key, value) => {
    setCallbackParams((prev: any) => ({ ...prev, [key]: value }))
  }, [])

  const value: any = useMemo(
    () => ({ callbackParams, accessCallbackParams }),
    [accessCallbackParams, callbackParams]
  )

  return (
    <PublicContext.Provider value={value}>{children}</PublicContext.Provider>
  )
}

const usePublicContext = () => useContext(PublicContext)

export { usePublicContext, PublicProvider }

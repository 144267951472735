import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { walletConnector } from './actions'

export function useWalletConnector(): (connectorName: any) => void {
  const dispatch = useAppDispatch()
  return useCallback(
    (connectorName: any) => {
      localStorage.setItem('walletConnector', connectorName)
      dispatch(walletConnector(connectorName))
    },
    [dispatch]
  )
}

import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { useEagerConnect, useInactiveListener } from 'hooks/web3'
import { network } from '../../connectors'
import { NetworkContextName } from '../../constants/misc'

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
`

const Message = styled.h2`
  color: ${({ theme }) => theme.secondary1};
`

export default function Web3ReactManager({
  children
}: {
  children: JSX.Element
}) {
  // const { active } = useWeb3React()
  // const {
  //   active: networkActive,
  //   error: networkError,
  //   activate: activateNetwork
  // } = useWeb3React(NetworkContextName)
  // const { activate, connector } = useWeb3React()
  // const logoutRofiAccount = useLogoutRofiAccount()
  // const { walletConnector } = useAppSelector((state) => state.connector)

  // const lastConnector: any = useMemo(() => {
  //   switch (walletConnector) {
  //     case 'blocto': {
  //       return blocto
  //     }
  //     case 'walletconnect': {
  //       return walletconnect
  //     }
  //     case 'injected': {
  //       return injected
  //     }
  //     default: {
  //       return undefined
  //     }
  //   }
  // }, [walletConnector])

  // const tokenApi = localStorage.getItem('token_api')

  // useEffect(() => {
  //   connector?.on('Web3ReactDeactivate', () => {
  //     logoutRofiAccount()
  //     localStorage.removeItem('token_api')
  //     localStorage.removeItem('sdk.session')
  //     localStorage.setItem('isAuthorized', '')
  //   })
  // }, [connector, logoutRofiAccount])
  // // try to eagerly connect to an injected provider, if it exists and has granted access already
  // const triedEager = useEagerConnect()

  // // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd

  // useEffect(() => {
  //   if (!networkActive && !networkError && !active && tokenApi) {
  //     activateNetwork(network)
  //     // activate(lastConnector)
  //   }
  // }, [
  //   // activate,
  //   activateNetwork,
  //   active,
  //   lastConnector,
  //   networkActive,
  //   networkError,
  //   tokenApi
  // ])

  // // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  // useInactiveListener(!triedEager)

  // // try to eagerly connect to an injected provider, if it exists and has granted access already

  // // on page load, do nothing until we've tried to connect to the injected connector
  // // if (!triedEager) {
  // //   return null
  // // }

  // // handle delayed loader state
  // const [showLoader, setShowLoader] = useState(false)
  // useEffect(() => {
  // 	const timeout = setTimeout(() => {
  // 		setShowLoader(true)
  // 	}, 600)

  // 	return () => {
  // 		clearTimeout(timeout)
  // 	}
  // }, [])

  // // on page load, do nothing until we've tried to connect to the injected connector
  // if (!triedEager) {
  // 	return null
  // }

  // // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  // if (!active && networkError) {
  // 	return (
  // 		<MessageWrapper>
  // 			<Message>
  // 				<Trans>
  // 					Oops! An unknown error occurred. Please refresh the page, or visit
  // 					from another browser or device.
  // 				</Trans>
  // 			</Message>
  // 		</MessageWrapper>
  // 	)
  // }

  // // if neither context is active, spin
  // if (!active && !networkActive) {
  // 	return showLoader ? (
  // 		<MessageWrapper>
  // 			<Loader />
  // 		</MessageWrapper>
  // 	) : null
  // }

  // return children
  const { active } = useWeb3React()
  const {
    active: networkActive,
    error: networkError,
    activate: activateNetwork
  } = useWeb3React(NetworkContextName)

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
  useEffect(() => {
    if (!networkActive && !networkError && !active) {
      activateNetwork(network)
    }
  }, [activateNetwork, active, networkActive, networkError])

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager)

  // handle delayed loader state
  const [showLoader, setShowLoader] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(true)
    }, 600)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  // on page load, do nothing until we've tried to connect to the injected connector
  // if (!triedEager) {
  //   return null
  // }

  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  if (!active && networkError) {
    return (
      <MessageWrapper>
        <Message>
          <Trans>
            Oops! An unknown error occurred. Please refresh the page, or visit
            from another browser or device.
          </Trans>
        </Message>
      </MessageWrapper>
    )
  }

  // if neither context is active, spin
  // if (!active && !networkActive) {
  //   return showLoader ? (
  //     <MessageWrapper>
  //       <Loader />
  //     </MessageWrapper>
  //   ) : null
  // }

  return children
}

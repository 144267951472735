import { createSlice } from '@reduxjs/toolkit'
import { authApple } from './action'

const initialState: {
  apple: any
} = {
  apple: JSON.parse(localStorage.getItem('apple-signin') || '{}')
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authApple.fulfilled, (state, { payload }) => {
      state.apple = payload
    })
  }
})

export const authReducer = authSlice.reducer

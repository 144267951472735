import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BloctoConnector } from '@blocto/blocto-connector'
import { SupportedChainId } from '../constants/chains'
import getLibrary from '../utils/getLibrary'

import { NetworkConnector } from './NetworkConnector'

const NETWORK_URLS: {
  [chainId in SupportedChainId]: string
} = {
  [SupportedChainId.ARBITRUM_ONE]: `https://arb1.arbitrum.io/rpc`,
  [SupportedChainId.BSC]: `https://bsc-dataseed1.ninicoin.io/`,
  [SupportedChainId.zkSYNC_ERA]: `https://mainnet.era.zksync.io`,
  [SupportedChainId.ETHEREUM]: `https://eth.llamarpc.com`,
  [SupportedChainId.POLYGON]: `https://polygon.llamarpc.com`,
  [SupportedChainId.OPTIMISM]: `https://mainnet.optimism.io`,
}

const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.BSC,
  SupportedChainId.zkSYNC_ERA,
  SupportedChainId.ETHEREUM,
  SupportedChainId.POLYGON,
  SupportedChainId.OPTIMISM,
]

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: 42161,
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? getLibrary(network.provider))
}

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
  rpc: NETWORK_URLS,
  qrcode: true,
  pollingInterval: 15000,
  bridge: 'https://bridge.walletconnect.org',
})

export const blocto = new BloctoConnector({
  chainId: 56,
  rpc: NETWORK_URLS[56],
})

// // mainnet only
// export const fortmatic = new FortmaticConnector({
//   apiKey: FORMATIC_KEY ?? '',
//   chainId: 1,
// })

// // mainnet only
// export const portis = new PortisConnector({
//   dAppId: PORTIS_ID ?? '',
//   networks: [1],
// })

// // mainnet only
// export const walletlink = new WalletLinkConnector({
//   url: NETWORK_URLS[56],
//   appName: 'Uniswap',
//   appLogoUrl: UNISWAP_LOGO_URL,
// })

import './App.css'

import Header from 'components/Header'
import Web3ReactManager from 'components/Web3ReactManager'
import { AuthProvider } from 'contexts/AuthContext'
import { AnimatePresence } from 'framer-motion'
import { useActiveWeb3React } from 'hooks/web3'
import React, { FC, Suspense, useEffect } from 'react'
import { NotificationContainer } from 'react-notifications'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import DarkModeQueryParamReader from 'theme/DarkModeQueryParamReader'

import { useWeb3React } from '@web3-react/core'
import Footer from 'components/Footer'
import MobileNavbar from 'components/MobileNavbar'
import { PublicProvider } from 'contexts/PublicContext'
import { useGlobalConfigSelector } from 'state/global/hooks'
import AirDrop from './Airdrop'
import CallbackPage from './Callback'
import { RedireactToDefaultPage } from './redirects'

const Earn = React.lazy(() => import('./Earn'))

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  .notification-container {
    top: 50px;
    width: 360px;
  }
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 0px 0px 0px;
  align-items: center;
  flex: 1;
  z-index: 1;
  /*
  ${({ theme }) => theme.mediaWidth.upTo576`
    padding: 6rem 16px 16px 16px;
  `};
*/
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
  transition: all 0.5s linear;
  ${({ theme }) => theme.mediaWidth.upTo576`
    display: block;
  `};
`
const MenuMobileWapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  z-index: 2;
  display: none;
  ${({ theme }) => theme.mediaWidth.upTo992`
    display: block;
  `};
`

const Parent: FC = ({ children }) => {
  const history = useHistory()
  const location = useLocation<any>()

  const { active } = useWeb3React()
  const { account } = useActiveWeb3React()
  const { config } = useGlobalConfigSelector()

  useEffect(() => {
    const publicPath = ['/airdrop', '/launchpad', '/callback', '/migrate']
    const login = account

    if (location.pathname === '/') {
      history.push({
        pathname: config.general.defaultPage
      })
      return
    }

    if (
      !publicPath.some((path) => location.pathname.includes(path)) &&
      !login
    ) {
      history.push({
        pathname: config.general.defaultPage,
        state: { from: location }
      })
      return
    }
  }, [account, active, config.general.defaultPage, history, location])

  return <>{children}</>
}

export default function App() {
  // const { isWrongNetwork } = useGlobalContext() as any

  return (
    <PublicProvider>
      <AuthProvider>
        <Parent>
          <div className='font-sans'>
            <Route component={DarkModeQueryParamReader} />
            <AppWrapper>
              <HeaderWrapper>
                <Header />
              </HeaderWrapper>
              {/* <AnimatePresence>
                {isWrongNetwork ? <WrongNetwork /> : null}
              </AnimatePresence> */}
              <BodyWrapper className='min-h-screen bg-tw-500 pb-[70px] lg:pb-0'>
                <div
                  className='min-h-[calc(100vh-120px-274.09px)] w-full 
                    sm:min-h-[calc(100vh-50px-274.09px)]'
                >
                  <Web3ReactManager>
                    <Route
                      render={({ location }) => (
                        <Suspense
                          fallback={
                            <div
                              className='flex h-full w-full flex-col items-center 
                                justify-center bg-tw-500 text-tw-200'
                            >
                              <img
                                src={
                                  'https://rofi-game-01.b-cdn.net/eragames/era-logo.svg'
                                }
                                alt='logo'
                                className='aspect-square w-20 object-contain'
                              />
                            </div>
                          }
                        >
                          <AnimatePresence
                            exitBeforeEnter
                            initial={false}
                            onExitComplete={() => {
                              if (typeof window !== 'undefined') {
                                window.scrollTo({ top: 0 })
                              }
                            }}
                          >
                            <Switch location={location} key={location.pathname}>
                              {/* Earn routes */}
                              <Route
                                exact
                                path='/callback/:redirectRoute'
                                component={CallbackPage}
                              />
                              <Route exact path='/earn' component={Earn} />
                              <Route
                                exact
                                path='/airdrop'
                                component={AirDrop}
                              />
                              {/* <Route
                                exact
                                path='/migrate'
                                component={Migrate}
                              /> */}
                              {/* <Route path='/launchpad' component={Launchpad} /> */}
                              {/* <Route path='/event' component={Event} exact /> */}
                              <Route
                                path='*'
                                component={RedireactToDefaultPage}
                              />
                            </Switch>
                          </AnimatePresence>
                        </Suspense>
                      )}
                    />
                  </Web3ReactManager>
                </div>
                {/* <Footer /> */}
                <Footer />
              </BodyWrapper>

              <NotificationContainer />
              <MenuMobileWapper>
                <MobileNavbar />
              </MenuMobileWapper>
            </AppWrapper>
          </div>
        </Parent>
      </AuthProvider>
    </PublicProvider>
  )
}

import styled from 'styled-components'

const FooterContainer = styled.footer``
const Logo = 'https://rofi-game-01.b-cdn.net/eragames/era-logo.svg'

const Footer = () => {
  return (
    <FooterContainer
      className='flex w-full flex-col items-center justify-center
			gap-[33px] bg-tw-800 px-[60px] py-[89px]'
    >
      <div className='w-[193px]'>
        <img
          src={Logo}
          alt='logo'
          className='w-full object-contain'
        />
      </div>
      <div className='flex w-full flex-wrap justify-center gap-1'>
        <p className='text-center'>© 2023 Era Protocol. All Rights Reserved.</p>
      </div>
    </FooterContainer>
  )
}

export default Footer

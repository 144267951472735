import DiscordIcon from 'assets/event/discord.svg'
import TwitterIcon from 'assets/event/twitter.svg'
import TwitterLogoAnimate from 'assets/rofi-games/airdrop/71665-twitter-icon.json'
import { AnimatePresence, motion } from 'framer-motion'
import { useLottie } from 'lottie-react'
import { useMemo } from 'react'
import ReactCountDown from 'react-countdown'

function addLeadingZero(number: number) {
  if (number === 0) return number
  return number.toString().padStart(2, '0')
}

const Countdown = () => {
  const options = useMemo(
    () => ({
      animationData: TwitterLogoAnimate,
      loop: true
    }),
    []
  )
  const { View } = useLottie(options)
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    return (
      <>
        {completed ? (
          <span className='text-3xl font-bold text-highlight sm:text-4xl'>
            Ended
          </span>
        ) : (
          <span className='text-3xl font-bold text-highlight sm:text-4xl'>
            {addLeadingZero(days)}D : {addLeadingZero(hours)}H :{' '}
            {addLeadingZero(minutes)}M : {addLeadingZero(seconds)}S
          </span>
        )}
      </>
    )
  }
  return (
    <div className='w-full text-center text-base'>
      {true ? (
        <p className='text-3xl font-bold text-redorange sm:text-4xl'>
          Round 1 ended!
        </p>
      ) : (
        <>
          <p className='text-lg text-white sm:text-xl'>
            Round 1 ends in <br />
            <ReactCountDown
              date={new Date(1687057200000)}
              renderer={renderer}
            />
          </p>
          <p className='mt-2 text-sm text-redorange sm:text-lg'>
            Hurry up before round 1 ends or all the tickets are sold out!
          </p>
        </>
      )}
      <p className='mt-5 text-sm sm:text-lg'>
        Join our community for more information
      </p>
      <div className='mt-3 flex justify-center gap-4'>
        <AnimatePresence>
          <a
            href='https://discord.com/invite/zH3bPcuHWC'
            target='_blank'
            rel='noreferrer'
          >
            <motion.img
              src={DiscordIcon}
              alt='discord logo'
              className='h-7 w-7 object-contain'
              animate={{ scale: [1.5, 1, 1.5] }}
              transition={{ repeat: Infinity }}
            />
          </a>
          <a
            href='https://twitter.com/era_prot'
            target='_blank'
            rel='noreferrer'
          >
            <motion.img
              src={TwitterIcon}
              alt='twitter logo'
              className='h-7 w-7 object-contain'
              animate={{ scale: [1, 1.5, 1] }}
              transition={{ repeat: Infinity }}
            />
          </a>
        </AnimatePresence>

        {/* <a href='https://twitter.com/era_prot' target='_blank' rel='noreferrer'>
          <div className='h-10 w-10'>{View}</div>
        </a> */}
      </div>
    </div>
  )
}

export default Countdown

import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'
import authApi from 'api/authApi'

const voidInit = () => {}

export const authApple = createAsyncThunk(
  'auth/apple',
  async (code: string) => {
    const result = await authApi.appleToken(code)
    return result
  }
)

export const actionAddAppleData = (
  code: string,
  onSuccess = voidInit,
  onError = voidInit
) => {
  return async (dispatch: any) => {
    try {
      const resultAction = await dispatch(authApple(code))
      const originalPromiseResult = unwrapResult(resultAction)
      onSuccess()
    } catch (rejectedValueOrSerializedError) {
      onError()
    }
  }
}

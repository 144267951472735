import { SYSTEM_CONFIG } from 'constants/endpointConfigs'
import axiosClient from './http-common'
const serviceApi = {
	getAllServiceStatus: (): Promise<any> => {
		const url = SYSTEM_CONFIG.BLOCKCHAIN_SERVICES
		return axiosClient.get(url)
	}
}

export default serviceApi

import { Listbox, Transition } from '@headlessui/react'
import { DocumentIcon } from '@heroicons/react/24/outline'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  TicketIcon
} from '@heroicons/react/24/solid'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import ERC20_ABI from 'abis/erc20.json'
import { Modal } from 'antd'
import airdropApi from 'api/airdrop'

import ArbitrumIcon from 'assets/rofi-games/airdrop/arbitrum.svg'
import BNBIcon from 'assets/rofi-games/airdrop/bnb.svg'
import USDTIcon from 'assets/rofi-games/airdrop/usdt.svg'
import ZkSyncIcon from 'assets/rofi-games/airdrop/zkSync.svg'
import ApproveImage from 'assets/rofi-games/approve-image.png'

import CustomButton from 'components/Buttons/CustomButton'
import { ButtonPrimary } from 'components/Buttons/HandleButton'
import InteractContractButton from 'components/Buttons/InteractContractButton/clone'
import CircleLoading from 'components/Icons/CircleLoading'
import DefaultModal from 'components/Modal/DefaultModal'
import new_network_config from 'data/airdropNetwork'
import { parseUnits } from 'ethers/lib/utils'
import notification from 'features/notification'
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion'
import { useApproveCallback } from 'hooks/useApproveCallback'
import { useAirdropPoolV1Contract } from 'hooks/useContract'
import useNetwork from 'hooks/useNetwork'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useToggle from 'hooks/useToggle'
import useUSDTAddress from 'hooks/useUSDTAddress'
import useUSDTCurrency from 'hooks/useUSDTCurrency'
import { useActiveWeb3React } from 'hooks/web3'
import JSBI from 'jsbi'
import { ConnectWalletOptions } from 'pages/MyAccount/Account/HyperCasual/components/CheckConnectWallet/ConnectWalletOption1'
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components/macro'
import { formatFixedNumber } from 'utils/formatBalance'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'

interface IProps {
  symbol: string
  description: any
  total: number
  amount: number
  price: number
  spread: boolean
  isComingSoon: boolean
  onSpread: () => void
  onUpdate: () => void
}

const ConnectWalletPopup = styled(Modal)`
  .ant-modal-body {
    padding: 30px 50px;
    ${({ theme }) => theme.mediaWidth.upTo1200`
      padding: 30px;
    `};
  }
  .ant-modal-header {
    background: #000b16;
    border-bottom: none !important;
    text-align: center;
    .ant-modal-title {
      color: #fff !important;
      font-size: 23px;
      font-weight: bold;
    }
  }
  .ant-modal-content {
    background: #000b16;
    border: 2px solid rgba(114, 124, 135, 0.3);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    .ant-modal-close {
      color: #fff !important;
      top: 5px;
      right: 5px;
      .ant-modal-close-x {
        width: 40px;
        height: 40px;
        line-height: 45px;
      }
    }
  }
`

const a_milion = '1000000000'

const currencyList = [
  // { name: 'USDT - Ethereum', image: EtherIcon, chainId: 1 },
  { name: 'USDT - BNB Smart Chain', image: BNBIcon, chainId: 56 },
  { name: 'USDT - Arbitrum One', image: ArbitrumIcon, chainId: 42161 },
  { name: 'USDT - zkSync Era', image: ZkSyncIcon, chainId: 324 }
]

const Detail = ({
  symbol,
  description,
  total,
  price,
  amount,
  spread,
  isComingSoon,
  onSpread,
  onUpdate
}: IProps) => {
  const [quantityBuy, setQuantityBuy] = useState(1) as any
  const [allowance, setAllowance] = useState(0) as any
  const [approved, setApprove] = useState(false) as any
  const [showApproveModal, setShowApproveModal] = useState(false) as any
  const [poolInformation, setPoolInformation] = useState({
    total: total,
    remaining: total,
    userBalance: 0
  }) as any
  const [isShowPopup, setIsShowPopup] = useState(false) as any
  const [spreadDescription, setSpreadDescription] = useState(false)
  const [now, setNow] = useState(Date.now())

  const { chainId, account } = useActiveWeb3React()
  const [selectedChainId, setSelectedChainId] = useState(chainId)

  //? Var
  const isSameChainId = useMemo(
    () => Number(selectedChainId) === Number(chainId),
    [chainId, selectedChainId]
  )
  const {
    visible: isSwitchingNw,
    enable: enableSwitchingNw,
    disable: disableSwitchingNw
  } = useToggle()

  const { enable, disable, visible } = useToggle(true)
  const web3 = useMemo(
    () =>
      chainId ? new Web3(new_network_config[chainId].rpcUrls[0]) : new Web3(),
    [chainId]
  )

  const airdropPoolContract = useAirdropPoolV1Contract()
  const address = useUSDTAddress() //TEMP: USDT ARB

  const tokenContract = new web3.eth.Contract(ERC20_ABI as AbiItem[], address)

  // const airdropPoolCFT = useMemo(() => {
  //   if (!AIRDROP_POOL_V1_ADDRESS[chainId || 42161]) return undefined
  //   return new web3.eth.Contract(
  //     AIRDROP_POOL_V1_ABI as AbiItem[],
  //     AIRDROP_POOL_V1_ADDRESS[chainId || 42161]
  //   )
  // }, [chainId, web3.eth.Contract])

  const currency = useUSDTCurrency()

  const typedValueParsed = useMemo(
    () => parseUnits(a_milion, currency?.decimals).toString(),
    [currency?.decimals]
  )

  let amountApprove = useMemo(
    () =>
      currency instanceof Token
        ? CurrencyAmount.fromRawAmount(currency, JSBI.BigInt(typedValueParsed))
        : undefined,
    [currency, typedValueParsed]
  )

  const [, approvalBUSDCallback] = useApproveCallback(
    amountApprove,
    airdropPoolContract?.address
  )

  // const updatePoolInformation = useCallback(async () => {
  //   if (!airdropPoolCFT) return
  //   const total = await airdropPoolCFT.methods.getTotal().call()
  //   const remaining = await airdropPoolCFT.methods.getRemaining().call()
  //   setPoolInformation((prev: any) => ({
  //     ...prev,
  //     total,
  //     remaining
  //   }))
  //   onUpdate()
  // }, [airdropPoolCFT, onUpdate])

  const updatePoolInformation = useCallback(async () => {
    onUpdate()
  }, [onUpdate])

  const updateUserInformation = useCallback(async (account: any) => {
    if (!account) {
      setPoolInformation((prev: any) => ({
        ...prev,
        userBalance: 0
      }))
      return
    }

    try {
      const result = await airdropApi.getUserInfo(account)
      setPoolInformation((prev: any) => ({
        ...prev,
        userBalance: result.data?.total || 0
      }))
    } catch (error) {
      console.error(error)
    }
  }, [])

  const getAllowance = useCallback(
    async (owner: any, spender: any) => {
      console.log('spender:', spender)
      if (!owner || !spender) return undefined
      const allowance = await tokenContract.methods
        .allowance(owner, spender)
        .call()
      console.log('allowance:', allowance)
      setAllowance(allowance)
      const formated = formatFixedNumber(allowance, chainId === 56 ? 18: 6, chainId === 56 ? 18: 6)
      const totalPrice = price * quantityBuy
      if (Number(formated) < totalPrice) {
        setApprove(false)
      } else {
        setApprove(true)
      }
      return allowance
    },
    [price, quantityBuy, tokenContract.methods]
  )

  const isAllowMint = useMemo(
    () =>
      currencyList.find((currency) => currency.chainId === chainId)
        ? !(account ?? undefined) || !airdropPoolContract?.address
        : false,
    [account, airdropPoolContract?.address, chainId]
  )

  useEffect(() => {
    if (!account) return
    getAllowance(account ?? undefined, airdropPoolContract?.address)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, airdropPoolContract?.address])

  useEffect(() => {
    updatePoolInformation()
  }, [updatePoolInformation])

  useEffect(() => {
    updateUserInformation(account)
  }, [account, updateUserInformation])

  const verify_tx = async (log: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const inputs = [
      {
        indexed: false,
        internalType: 'address',
        name: 'account',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256'
      }
    ]

    // log.topics.shift()
    // const decoded = web3.eth.abi.decodeLog(inputs, log.data, [log.topics])
    // console.log('decoded', decoded)
    await updatePoolInformation()
    await updateUserInformation(account)
    setTimeout(() => {
      notification.success('Success', 'Mint NFT successfully')
    }, 3000)
  }

  const checkAllowance = async () => {
    const allowance = await getAllowance(
      account ?? undefined,
      airdropPoolContract?.address
    )
    if (!allowance) return
    const formated = formatFixedNumber(allowance, chainId === 56 ? 18: 6, chainId === 56 ? 18: 6)
    const totalPrice = price * quantityBuy
    if (Number(formated) < totalPrice) {
      setShowApproveModal(true)
    }
    return
  }

  useEffect(() => {
    const itv = setInterval(() => {
      setNow(Date.now())
    }, 1000)
    return () => clearInterval(itv)
  }, [])

  return (
    <motion.div
      initial={{
        height: 0
      }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      transition={{ type: 'just' }}
      className={visible ? 'overflow-hidden' : 'overflow-visible'}
    >
      <div
        className='mt-2 flex flex-wrap justify-between gap-2.5 rounded-5 bg-tw-700 
        p-2.5'
      >
        <div
          className='w-full space-y-2.5 rounded-5 border border-border01 p-2.5 
          md:w-[calc(50%-5px)]'
        >
          <div className='flex items-center gap-[5px]'>
            <DocumentIcon className='w-[22px] text-highlight' />
            <h5 className='text-lg font-semibold'>Project Summary</h5>
          </div>
          <div className='text-sm'>
            <p className='font-bold'>{description.title}</p>
            <div className='hidden w-full md:block'>
              {description.content.map((line: string, idx: number) => (
                <p style={{ padding: '5px 0' }} key={idx}>
                  {line}
                </p>
              ))}
            </div>
            <motion.div
              initial={{ height: '0px' }}
              animate={{ height: !spreadDescription ? '150px' : 'auto' }}
              className={`relative overflow-hidden md:hidden`}
            >
              <div
                className='absolute bottom-0 flex w-full cursor-pointer justify-center
                gap-[7px] bg-gradient-to-t from-tw-700 to-tw-700/0 
                pb-2 pt-6 font-bold text-white
                transition-colors duration-200 ease-in-out hover:text-redorange'
                onClick={() => setSpreadDescription(!spreadDescription)}
              >
                {!spreadDescription ? 'More' : 'Less'}
                <ChevronDownIcon
                  className={`w-4 ${
                    spreadDescription ? 'rotate-180' : 'rotate-0'
                  }`}
                />
              </div>
              <div className='w-full'>
                {description.content.map((line: string, idx: number) => (
                  <p style={{ padding: '5px 0' }} key={idx}>
                    {line}
                  </p>
                ))}
                <br />
                <br />
              </div>
            </motion.div>
          </div>
        </div>

        <div
          className={`flex w-full flex-wrap gap-2.5 rounded-5
          border-2 border-border01 bg-tw-500 p-2.5 md:w-[calc(50%-5px)]
          ${visible ? 'overflow-hidden' : 'overflow-visible'}`}
        >
          <div className='w-full md:w-32'>
            <div className='mx-auto max-w-[8rem] space-y-2.5'>
              <div
                className='w-full overflow-hidden rounded-5 border 
                border-tw-200 bg-tw-500'
              >
                <img
                  src={symbol}
                  alt='symbol'
                  className='aspect-square w-full object-cover'
                />
              </div>
              <p className='!mb-[5px] text-center text-white'>Era Ticket NFT</p>
            </div>
          </div>
          <div className='w-full space-y-2.5 md:w-[calc(100%-8rem-10px)] '>
            <ul className='w-full'>
              <li className='flex justify-between border-b border-border01 py-2.5'>
                <p className=''>Total NFT of pool</p>
                <p className='text-highlight'>{total}</p>
              </li>
              <li className='flex justify-between border-b border-border01 py-2.5'>
                <p className=''>Remaining</p>
                <p className='text-highlight'>{amount > 0 ? amount : 0}</p>
              </li>
              <li className='flex justify-between border-b border-border01 py-2.5'>
                <p className=''>Your NFT balance</p>
                <p className='text-highlight'>
                  {!isComingSoon ? poolInformation.userBalance : 0}
                </p>
              </li>
              <li className='flex justify-between border-b border-border01 py-2.5'>
                <p className=''>Share of pool</p>
                <p className='text-highlight'>
                  {Number(
                    total !== 0 && (poolInformation.userBalance / total) * 100
                  ).toFixed(2)}
                  %
                </p>
              </li>
            </ul>

            <div className='rounded-5 border border-border01 bg-border01 p-[15px]'>
              <div className='space-y-2.5'>
                <form
                  className='space-y-2.5'
                  onSubmit={(e) => e.preventDefault()}
                >
                  <label className='mb-1 text-lg text-white'>BUY</label>
                  <div
                    className='relative flex w-full justify-between 
                    rounded-[5px] bg-neutral-100 px-2.5 py-2.5 
                    font-semibold'
                  >
                    <input
                      type='number'
                      className='input-default pr-2'
                      value={quantityBuy}
                      onChange={({ target }) => {
                        if (Number(target.value) > amount)
                          return setQuantityBuy(amount)
                        setQuantityBuy(target.value)
                      }}
                    />
                    <p
                      className='cursor-pointer text-tw-800 hover:underline'
                      onClick={() => {
                        setQuantityBuy(amount)
                      }}
                    >
                      Max
                    </p>
                  </div>
                </form>

                <ul className='w-full space-y-2.5'>
                  <li className='flex justify-between'>
                    <p className=''>Price</p>
                    <p className='text-highlight'>{price} USD</p>
                  </li>
                  <li className='flex justify-between'>
                    <p className=''>Total</p>
                    <p className='text-highlight'>{price * quantityBuy} USD</p>
                  </li>
                </ul>

                <div className='h-[1px] w-full bg-white/10'></div>

                <div className=''>
                  {isComingSoon ? (
                    <div>
                      <CustomButton
                        disable={true}
                        onClick={() => setIsShowPopup(true)}
                        size='large'
                        className='text-base text-white'
                      >
                        COMING SOON
                      </CustomButton>
                    </div>
                  ) : (
                    <div>
                      {!account ? (
                        <CustomButton
                          disable={false}
                          onClick={() => setIsShowPopup(true)}
                          size='large'
                          className='text-base text-white'
                        >
                          CONNECT WALLET
                        </CustomButton>
                      ) : (
                        <div className='space-y-2.5'>
                          <p className=''>Payment Method</p>

                          <div className='w-full'>
                            <SelectCurrency
                              onOpen={enable}
                              onClose={disable}
                              chainIdSelected={(value: any) => {
                                setSelectedChainId(value)
                              }}
                              switching={{
                                isSwitchingNw,
                                enableSwitchingNw,
                                disableSwitchingNw
                              }}
                              callback={checkAllowance}
                            />
                          </div>

                          {isSwitchingNw ? (
                            <p className='text-redorange'>
                              Please confirm on your wallet.
                            </p>
                          ) : isAllowMint ? (
                            <p className='text-redorange'>
                              Can not detect contract address
                            </p>
                          ) : null}

                          {amount <= 0 || now >= 1687057200000 ? (
                            <div className='flex cursor-not-allowed items-center justify-center gap-2 rounded-5 bg-red-500 py-2.5 text-center text-base text-white'>
                              <TicketIcon className='w-5 text-white' /> Sold out
                            </div>
                          ) : (
                            <>
                              {approved ? (
                                <InteractContractButton
                                  contract={airdropPoolContract}
                                  data={[quantityBuy]}
                                  callback={verify_tx}
                                  methodName='claim'
                                  disable={
                                    !isSameChainId ||
                                    isSwitchingNw ||
                                    Number(quantityBuy) <= 0 ||
                                    isAllowMint
                                  }
                                  tooltip={''}
                                  size='large'
                                  className='btn-gradient-1 w-full before:hover:!opacity-0'
                                >
                                  MINT NFT
                                </InteractContractButton>
                              ) : (
                                <CustomButton
                                  disable={
                                    !isSameChainId ||
                                    isSwitchingNw ||
                                    Number(quantityBuy) <= 0 ||
                                    isAllowMint
                                  }
                                  onClick={() => {
                                    checkAllowance()
                                  }}
                                  size='large'
                                  className='text-base text-white hover:before:!opacity-0'
                                >
                                  MINT NFT
                                </CustomButton>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <DefaultModal
          title='Connect'
          visible={isShowPopup}
          onClose={() => {
            setIsShowPopup(false)
          }}
          className='!w-[400px]'
        >
          <div className='mt-5 space-y-2'>
            <ConnectWalletOptions onSuccess={() => setIsShowPopup(false)} />
          </div>
        </DefaultModal>

        <DefaultModal
          visible={showApproveModal}
          title={'Mint NFT not ready!'}
          onClose={() => setShowApproveModal(false)}
          className={'!w-[500px]'}
        >
          <div className='space-y-2.5'>
            <div className='text-center'>
              <p className='text-[24px] font-medium leading-[29px] text-redorange'>
                Spend amount exceeds allowance.
              </p>
              <p className='text-sm font-medium text-textcolor'>
                You need to approve at least total price
              </p>
            </div>
            <div className='flex w-full items-center justify-center gap-2.5'>
              <div className=''>
                <div className='flex space-x-[5px]'>
                  <p className='text-[32px] font-medium leading-[36px] text-redorange'>
                    {(price * quantityBuy).toFixed(1)}
                  </p>
                  <span className='text-sm text-tw-50'>USD</span>
                </div>
                <p className='text-base font-normal text-white'>Total price</p>
              </div>
              <div className='w-10'>
                <ChevronRightIcon className='w-full text-tw-50' />
              </div>
              <div className=''>
                <div className='flex space-x-[5px]'>
                  <p className='text-[32px] font-medium leading-[36px] text-highlight'>
                    {formatFixedNumber(allowance, chainId === 56 ? 18: 6, chainId === 56 ? 18: 6)}
                  </p>
                  <span className='text-sm text-tw-50'>USD</span>
                </div>
                <p className='text-base font-normal text-white'>Allowanced</p>
              </div>
            </div>
          </div>
          {!isMobile && (
            <div
              className='mt-15px flex w-full flex-col items-center 
                gap-2.5 border-t border-border01 pt-15px'
            >
              <p className='text-center text-sm font-medium text-textcolor'>
                Note: Please enter the approve amount on Wallet input or click
                Use default to set unlimited amount.
              </p>
              <div className='flex flex-col items-center'>
                <img
                  src={ApproveImage}
                  alt=''
                  className='w-full max-w-[320px] object-contain'
                />
                <p className='mt-2 text-xs italic'>Example image</p>
              </div>
            </div>
          )}
          <CustomButton
            disable={false}
            onClick={() => {
              approvalBUSDCallback().then(() => {
                setShowApproveModal(false)
                setApprove(true)
              })
            }}
            size='large'
            className='mt-[30px] w-full text-center'
          >
            Approve
          </CustomButton>
        </DefaultModal>

        <div className='block w-full md:hidden'>
          <ButtonPrimary
            size='large'
            className='flex w-full items-center justify-center
            gap-3'
            onClick={onSpread}
          >
            <motion.div
              className='w-12 overflow-hidden text-base font-medium 
                text-white'
            >
              <AnimatePresence exitBeforeEnter initial={false}>
                {spread ? (
                  <motion.p
                    initial={{ y: 15, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 15, opacity: 0 }}
                    key={'btn-text-1'}
                  >
                    Less
                  </motion.p>
                ) : (
                  <motion.p
                    initial={{ y: -15, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -15, opacity: 0 }}
                    key={'btn-text-2'}
                  >
                    More
                  </motion.p>
                )}
              </AnimatePresence>
            </motion.div>
            <ChevronDownIcon
              className={`w-5 text-white transition-transform
                duration-200 ease-linear
                ${spread ? 'rotate-180' : 'rotate-0'}`}
            />
          </ButtonPrimary>
        </div>
      </div>
    </motion.div>
  )
}

export default Detail

const SelectCurrency = ({
  onOpen,
  onClose,
  chainIdSelected,
  switching: { isSwitchingNw, enableSwitchingNw, disableSwitchingNw },
  callback
}: any) => {
  const [selected, setSelected] = useState() as any
  const [open, setOpen] = useState(false)

  const listBoxRef = useRef() as any
  useOnClickOutside(listBoxRef, () => setOpen(false))

  const { chainId } = useActiveWeb3React()

  const { switchNetwork } = useNetwork()

  useEffect(() => {
    if (open) onClose()
    else
      setTimeout(() => {
        onOpen()
      }, 200)
  }, [onClose, onOpen, open])

  useEffect(() => {
    if (currencyList.find((currency) => currency.chainId === chainId)) {
      setSelected(
        currencyList.find((currency: any) => currency.chainId === chainId)
      )
    } else {
      setSelected(null)
    }
  }, [chainId])

  useEffect(() => {
    chainIdSelected(selected?.chainId)
  }, [chainIdSelected, selected?.chainId])

  return (
    <div className='w-full' ref={listBoxRef}>
      <Listbox value={selected} onChange={setSelected}>
        <div className='relative mt-1'>
          <Listbox.Button
            className='relative w-full cursor-pointer rounded-5 bg-white py-[13px] pl-[38px] 
            pr-10 text-left 
            shadow-md 
            focus:outline-none 
            focus-visible:border-indigo-500 
            focus-visible:ring-2 
            focus-visible:ring-white 
            focus-visible:ring-opacity-75 
            focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300
            sm:text-sm'
            onClick={() => {
              onClose()
              setTimeout(() => {
                setOpen(!open)
              }, 100)
            }}
          >
            {selected?.name ? (
              <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600'>
                <img src={USDTIcon} alt='' className='' />
              </span>
            ) : null}

            <span className='block truncate font-semibold text-tw-800'>
              {selected?.name || 'Select currency'}
            </span>

            {isSwitchingNw ? (
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <CircleLoading className='h-5 w-5 !text-redorange' />
              </span>
            ) : (
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <ChevronDownIcon
                  className='h-5 w-5 text-tw-800'
                  aria-hidden='true'
                />
              </span>
            )}
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options
              static
              className='absolute z-10 mt-1 max-h-60 w-full 
              divide-y divide-tw-600 overflow-auto rounded-5 bg-white text-base shadow-lg 
              ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
            >
              <Listbox.Option
                key={'select'}
                className={({ active }) =>
                  `relative cursor-pointer select-none py-[11px] px-[38px] ${
                    active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                  }`
                }
                value={{}}
                onClick={() => {
                  onClose()
                  setTimeout(() => {
                    setOpen(!open)
                  }, 100)
                }}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate text-sm leading-[17px] text-tw-800 ${
                        selected ? 'font-bold' : 'font-semibold'
                      }`}
                    >
                      Select currency
                    </span>
                  </>
                )}
              </Listbox.Option>
              {currencyList.map((currency, idx) => (
                <Listbox.Option
                  key={currency.name}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-[11px] px-[38px] ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={currency}
                  onClick={() => {
                    onClose()
                    setTimeout(() => {
                      setOpen(!open)
                    }, 100)

                    switchNetwork(
                      currency.chainId,
                      enableSwitchingNw,
                      () => {
                        disableSwitchingNw()
                        callback()
                      },
                      () => {
                        disableSwitchingNw()
                      }
                    )
                  }}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate text-sm leading-[17px] text-tw-800 ${
                          selected ? 'font-bold' : 'font-semibold'
                        }`}
                      >
                        {currency.name}
                      </span>
                      <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600'>
                        <img src={USDTIcon} alt='usdt icon' className='' />
                      </span>
                      <span className='absolute inset-y-0 right-0 flex items-center pr-3 text-amber-600'>
                        <img
                          src={currency.image}
                          alt={currency.name}
                          className=''
                        />
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'
import globalApi from 'api/globalApi'

const voidInit = () => {}

// fired once when the app reloads but before the app renders
// allows any updates to be applied to store data loaded from localStorage
export const updateVersion = createAction<void>('global/updateVersion')

export const fetchConfig = createAsyncThunk('global/getConfig', async () => {
  const result = await globalApi.getConfig()
  return result.data
})

export const actionFetchConfig = (onSuccess = voidInit, onError = voidInit) => {
  return async (dispatch: any) => {
    try {
      const resultAction = await dispatch(fetchConfig())
      unwrapResult(resultAction)
      onSuccess()
    } catch (rejectedValueOrSerializedError) {
      onError()
    }
  }
}

import { SYSTEM_CONFIG } from 'constants/endpointConfigs'
import axiosClient from './http-common'

const globalApi = {
  getConfig: (): Promise<any> => {
    const url = SYSTEM_CONFIG.WEB_CONFIG
    return axiosClient.get(url)
  }
}

export default globalApi

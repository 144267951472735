import { BloctoConnector } from '@blocto/blocto-connector'
import BloctoSDK from '@blocto/sdk'
import { InjectedConnector } from '@web3-react/injected-connector'
import { formatFixedNumber } from 'utils/formatBalance'
import Web3 from 'web3'
import WETH_ABI from 'abis/weth.json'

const web3 = new Web3(process.env.REACT_APP_RPC_ENDPOINT || '')
const WETH_ARB = '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'
const WETH_contract = new web3.eth.Contract(
	WETH_ABI,
	WETH_ARB
)

export const connectWallet = async () => {
	if (!window.ethereum) {
		throw new Error(
			'Web3 provider not found. Please install Metamask and try again.'
		)
	}
	const accounts = await window.ethereum.request({
		method: 'eth_requestAccounts'
	})
	const current_account = accounts[0]
	return current_account
}

export const signMessage = async (account, type, connector) => {
	let message

	let retype =
		connector instanceof InjectedConnector
			? 'injected'
			: connector instanceof BloctoConnector
			? 'blocto'
			: type

	switch (retype) {
		case 'injected':
			message = await signMessageWithInjected(account)
			return message
		case 'blocto':
			message = await signMessageWithBlocto(account, connector)
			return message
		case 'walletconnect':
			message = await signMessageWithWalletConnect(account, connector)
			return message
		default:
			break
	}
}

const signMessageWithInjected = async account => {
	const web3_current_provider = new Web3(window.ethereum)
	try {
		const message = new Date().getTime() + '.' + account + '.rofi.games'
		const signedSignature = await web3_current_provider.eth.personal.sign(
			message,
			account
		)
		return `${message}-${signedSignature}`
	} catch (e) {
		return undefined
	}
}

const signMessageWithBlocto = async (account, connector) => {
	const bloctoSDK = new BloctoSDK({
		ethereum: {
			chainId: connector.chainId,
			rpc: connector.rpc
		}
	})
	const web3 = new Web3(bloctoSDK.ethereum)
	try {
		const message = new Date().getTime() + '.' + account + '.rofi.games'
		const signedSignature = await web3.eth.sign(message, account)
		return `${message}-${signedSignature}`
	} catch (e) {
		console.log(e)
		return undefined
	}
}

const signMessageWithWalletConnect = async (account, connector) => {
	const provider = connector.walletConnectProvider
	const web3 = new Web3(provider)
	try {
		const message = new Date().getTime() + '.' + account + '.rofi.games'
		const signedSignature = await web3.eth.personal.sign(message, account)
		return `${message}-${signedSignature}`
	} catch (e) {
		return undefined
	}
}

export const getETHBalance = async (account) => {
	if (!account) return 0
	const balance = await web3.eth.getBalance(account)
	return formatFixedNumber(balance)
}

export const getWETHBalance = async (address) => {
	if (!address) return 0
	const balance = await WETH_contract.methods.balanceOf(address).call()
	return formatFixedNumber(balance)
}

export const signLoginMessage = async account => {
	const web3_current_provider = new Web3(window.ethereum)
	try {
	  const message = new Date().getTime() + '.' + account + '.rofi.games'
	  const signedSignature = await web3_current_provider.eth.personal.sign(
		message,
		account
	  )
	  return `${message}-${signedSignature}`
	} catch (e) {
		  return undefined
	}
  }
import { CheckIcon } from '@heroicons/react/24/solid'
import { Modal } from 'antd'
import { ButtonPrimary } from 'components/Buttons/HandleButton'

interface ISuccessTrans {
	visible: boolean
	onClose: () => void
}

const modalStyle = {
	border: '1px solid rgba(114, 124, 135, 0.3)',
	borderRadius: '5px',
	backgroundColor: '#161C24',
	boxShadow:
		'0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)'
}

const SuccessTransactionModal = ({ visible, onClose }: ISuccessTrans) => {
	return (
		<Modal
			footer={null}
			visible={visible}
			centered
			className={'success-modal'}
			style={modalStyle}
			closeIcon={<div className='hidden'></div>}
		>
			<div className='flex w-full items-center flex-col gap-4'>
				<div
					className='w-16 h-16 rounded-full border-2 border-emerald-500
          flex justify-center items-center'
				>
					<CheckIcon className='w-10 text-emerald-500' />
				</div>
				<h4 className='text-center text-emerald-500 font-bold text-2xl lg:text-3xl'>
					Transaction successfully
				</h4>
				<div className=''>
					<ButtonPrimary className='btn-white' onClick={onClose}>
						Close
					</ButtonPrimary>
				</div>
			</div>
		</Modal>
	)
}

export default SuccessTransactionModal

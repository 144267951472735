import { ChevronDownIcon } from '@heroicons/react/24/outline'
import TwitterLogoAnimate from 'assets/rofi-games/airdrop/71665-twitter-icon.json'
import { ButtonPrimary } from 'components/Buttons/HandleButton'
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion'
import { useLottie } from 'lottie-react'
import { useMemo } from 'react'
import ProgressBar from './ProcessBar'
interface IProps {
  spread: boolean
  name: string
  symbol: string
  currency: string
  total: number
  amount: number
  time: string
  price: number
  isComingSoon: boolean
  onClick: () => void
}

function addLeadingZero(number: number) {
  return number.toString().padStart(2, '0')
}

// const renderer = ({ days, hours, minutes, seconds }: any) => {
//   return (
//     <p className='text-sm text-white md:text-sm xl:text-lg'>
//       {days && `${addLeadingZero(days)}D:`}
//       {hours && `${addLeadingZero(hours)}H:`}
//       {minutes && `${addLeadingZero(minutes)}M:`}
//       {seconds && `${addLeadingZero(seconds)}S`}
//     </p>
//   )
// }

const getPercent = (currentValue = 1, max = 1) =>
  (currentValue / max) * 100 > 100 ? 100 : (currentValue / max) * 100

const Overview = ({
  spread,
  name,
  symbol,
  currency,
  total,
  amount,
  price,
  time,
  isComingSoon,
  onClick
}: IProps) => {


  return (
    <div
      className={`flex cursor-pointer gap-[5px] 
      rounded-5 p-[5px] transition duration-200
      ease-linear hover:bg-tw-700 md:items-center lg:p-2.5
      ${spread ? 'bg-tw-700' : ''}`}
      onClick={onClick}
    >
      <div
        className='h-[128px] min-h-[128px] w-[128px] min-w-[128px] 
        overflow-hidden rounded-5 border 
        border-tw-200 bg-tw-500'
      >
        <img
          src={symbol}
          alt='symbol'
          className='aspect-square w-full object-cover'
        />
      </div>
      <div
        className='flex w-full flex-col gap-2.5 p-[5px] 
        xl:flex-row xl:items-center xl:justify-between'
      >
        <div
          className='space-y-[5px] border-b border-tw-200 pb-[5px]
          md:space-y-2.5 md:pb-2.5 xl:border-none'
        >
          <h4 className='text-base font-semibold text-white md:text-2xl'>
            {name}
          </h4>
          <p className='text-xs font-semibold md:text-sm'>
            Era Ticket NFT = <span className='text-white'>{price}</span> USDT
            {/* Era Ticket NFT */}
          </p>
        </div>
        <div
          className='flex w-full flex-col gap-2.5 
          sm:flex-row sm:justify-between 
          xl:w-max xl:gap-10'
        >
          {isComingSoon ? (
            <p></p>
          ) : (
            <div className='flex flex-wrap items-center justify-center gap-[7.5px] sm:gap-4 xl:gap-5 2xl:gap-10'>
              <div className='w-full gap-[5px] sm:w-[250px]'>
                <div className='mb-[1px] flex items-center justify-end'>
                  <p className='text-sm font-bold text-redorange'>
                    {`${getPercent(total - amount, total).toFixed(2)}`}%
                  </p>
                </div>
                <ProgressBar percent={getPercent(total - amount, total)} />
              </div>
              <div className='flex w-full items-center justify-center sm:w-max'>
                <div className='text-sm text-white md:text-sm xl:text-lg'>
                  <p className='text-center font-bold'>
                    {isComingSoon ? '' : `${total} tickets`}
                  </p>
                  <p className='text-center text-xs md:text-sm'>
                    {isComingSoon ? '' : `~ ${currency}`}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className=''>
            <ButtonPrimary
              className='flex w-full items-center justify-center
              gap-3'
            >
              <motion.div
                className='w-12 overflow-hidden text-base font-medium 
                text-white'
              >
                <AnimatePresence exitBeforeEnter initial={false}>
                  {spread ? (
                    <motion.p
                      initial={{ y: 15, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: 15, opacity: 0 }}
                      key={'btn-text-1'}
                    >
                      Less
                    </motion.p>
                  ) : (
                    <motion.p
                      initial={{ y: -15, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -15, opacity: 0 }}
                      key={'btn-text-2'}
                    >
                      More
                    </motion.p>
                  )}
                </AnimatePresence>
              </motion.div>
              <ChevronDownIcon
                className={`w-5 text-white transition-transform
                duration-200 ease-linear
                ${spread ? 'rotate-180' : 'rotate-0'}`}
              />
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview

import useScrollPosition from '@react-hook/window-scroll'
import { useWeb3React } from '@web3-react/core'
// import {
//   default as Logo,
//   default as LogoMobile
// } from 'assets/rofi-games/logo.svg'
import { useActiveWeb3React } from 'hooks/web3'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { RowFixed } from '../Row'
import Web3Status from '../Web3Status'

import {
  ArrowRightOnRectangleIcon,
  WalletIcon
} from '@heroicons/react/24/solid'
import CustomButton from 'components/Buttons/CustomButton'
import { ButtonPrimary } from 'components/Buttons/HandleButton'
import DefaultModal from 'components/Modal/DefaultModal'
import useWindowSize from 'hooks/useWindowSize'
import { ConnectWalletOptions } from 'pages/MyAccount/Account/HyperCasual/components/CheckConnectWallet/ConnectWalletOption1'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { returnToInitialWallet } from 'state/wallet/action'
import tw from 'twin.macro'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 7px 15.73px 7px 24px;
  top: 0;
  position: relative;
  z-index: 21;
  position: relative;
  height: 50px;
  transition: all 1s linear;
`

const HeaderFrameMobile = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 0 15px;
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upTo768`
    flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upTo768`
   width: max-content
  `};
`

const AccountMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const HeaderLinks = styled.div`
  padding-left: 12.5px;
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  ${({ theme }) => theme.mediaWidth.upTo992`
    margin-left: 15px;
  `};
  ${({ theme }) => theme.mediaWidth.upTo576`
    display: block;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: inherit;
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  // margin-right: 15px;
  :focus {
    border: 1px solid blue;
  }
  ${({ theme }) => theme.mediaWidth.upTo576`
  `};
`

const Title = styled(NavLink)`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upTo576`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const LogoIcon = styled.div`
  img {
    height: 30px;
    width: max-content;
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${() => tw`border-tw-500 text-base`}
  ${({ theme }) => theme.flexRowNoWrap}
  outline: none;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  padding: 0 7.5px;
  word-break: break-word;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  &.sub-menu {
    background: #121212;
    padding: 0 25px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    &.${activeClassName} {
      border: none;
    }
  }
  &.${activeClassName} {
    color: #fe4a31;
  }

  :hover {
    color: #fe4a31;
  }

  ${({ theme }) => theme.mediaWidth.upTo992`
    margin: 0 5px;
  `};

  ${({ theme }) => theme.mediaWidth.upTo576`
    float: right;
  `};
`

const StyledOutNavLink = styled.a.attrs({
  activeClassName
})`
  ${() => tw`border-tw-500 text-base`}
  ${({ theme }) => theme.flexRowNoWrap}
  outline: none;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  padding: 0 7.5px;
  word-break: break-word;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  &.sub-menu {
    background: #121212;
    padding: 0 25px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    &.${activeClassName} {
      border: none;
    }
  }
  &.${activeClassName} {
    color: #fe4a31;
  }

  :hover {
    color: #fe4a31;
  }

  ${({ theme }) => theme.mediaWidth.upTo992`
    margin: 0 5px;
  `};

  ${({ theme }) => theme.mediaWidth.upTo576`
    float: right;
  `};
`

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg2};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const Logo = 'https://rofi-game-01.b-cdn.net/eragames/era-logo.svg'
const LogoMobile = 'https://rofi-game-01.b-cdn.net/eragames/era-logo.svg'

export default function Header() {
  //? Redux
  const dispatch = useDispatch()

  //? State
  const [isShowPopup, setIsShowPopup] = useState(false)

  //? Var
  const { deactivate } = useWeb3React()
  const { account } = useActiveWeb3React()

  const scrollY = useScrollPosition()

  const { width } = useWindowSize() as any
  const isMobile = useMemo(() => width <= 1024, [width])

  //? Func
  const handle_logout = () => {
    if (account) deactivate()
    localStorage.removeItem('walletConnector')
    localStorage.removeItem('sdk.session')
    localStorage.setItem('isAuthorized', '')
    dispatch(returnToInitialWallet)
  }

  const renderAccountActionMobile = () => {
    if (account) {
      return (
        <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
          <Web3Status />
          <div className='w-6' onClick={handle_logout}>
            <ArrowRightOnRectangleIcon
              className='w-6 text-tw-400 
								transition-all
								duration-200 ease-linear group-hover:text-primary'
            />
          </div>
        </AccountElement>
      )
    }
    return (
      <CustomButton
        onClick={() => {
          setIsShowPopup(true)
        }}
      >
        <div className='flex items-center gap-2'>
          <WalletIcon className='w-5 text-white' />
          <span className='text-white'>Connect</span>
        </div>
      </CustomButton>
    )
  }

  const renderAccountAction = () => {
    if (account) {
      return (
        <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
          <Web3Status />
          <ButtonPrimary className='btn-red' onClick={handle_logout}>
            Disconnect
          </ButtonPrimary>
        </AccountElement>
      )
    }

    return (
      <CustomButton onClick={() => setIsShowPopup(true)}>
        <div className='flex items-center gap-2'>
          <WalletIcon className='w-5 text-white' />
          <span className='text-white'>Connect Wallet</span>
        </div>
      </CustomButton>
    )
  }

  if (isMobile) {
    return (
      <>
        <HeaderFrameMobile
          className='border-b border-b-border01 bg-tw-500/60 
          backdrop-blur-lg'
        >
          <HeaderRow>
            <Title to={'/'}>
              <LogoIcon>
                <img src={LogoMobile} alt='logo' />
              </LogoIcon>
            </Title>
          </HeaderRow>
          <AccountMobile>{renderAccountActionMobile()}</AccountMobile>
        </HeaderFrameMobile>
        <DefaultModal
          title='Connect'
          visible={isShowPopup}
          onClose={() => {
            setIsShowPopup(false)
          }}
          className='!w-[400px]'
        >
          <div className='mt-5 space-y-2'>
            <ConnectWalletOptions onSuccess={() => setIsShowPopup(false)} />
          </div>
        </DefaultModal>
      </>
    )
  }

  return (
    <>
      <HeaderFrame
        showBackground={scrollY > 45}
        className='border-b border-b-border01 bg-tw-500/60 
        backdrop-blur-lg'
      >
        <HeaderRow>
          <Title to={'/'}>
            <LogoIcon>
              <img src={Logo} alt='logo' />
            </LogoIcon>
          </Title>
          <HeaderLinks>
            <StyledNavLink to={'/airdrop'} className='text-white'>
              Airdrop
            </StyledNavLink>
          </HeaderLinks>
          <HeaderLinks className='relative'>
            <StyledNavLink
              to={'/launchpad'}
              className='text-white'
              onClick={(e) => e.preventDefault()}
            >
              Launchpad
            </StyledNavLink>
            <div className='absolute right-[-5px] -top-2.5 text-xs font-semibold text-redorange'>
              Soon
            </div>
          </HeaderLinks>
          {/* <HeaderLinks>
            <StyledNavLink to={'/migrate'} className='text-white'>
              Migrate
            </StyledNavLink>
          </HeaderLinks> */}
          <HeaderLinks className='relative'>
            <StyledOutNavLink
              href={'https://games.eraprotocol.io'}
              className='text-white'
            >
              Games
            </StyledOutNavLink>
            <div className='absolute right-[-5px] -top-2.5 text-xs font-semibold text-redorange'>
              New
            </div>
          </HeaderLinks>
          {/* <HeaderLinks>
            <StyledNavLink to={'/event'} className='text-textcolor'>
              Event
            </StyledNavLink>
          </HeaderLinks> */}
        </HeaderRow>
        <HeaderControls>
          <HeaderElement>{renderAccountAction()}</HeaderElement>
        </HeaderControls>
      </HeaderFrame>

      <DefaultModal
        title='Connect'
        visible={isShowPopup}
        onClose={() => {
          setIsShowPopup(false)
        }}
        className='!w-[400px]'
      >
        <div className='mt-5 space-y-2'>
          <ConnectWalletOptions onSuccess={() => setIsShowPopup(false)} />
        </div>
      </DefaultModal>
    </>
  )
}

// const DropdownMenuMobile = () => {
//   const [show, setShow] = useState(false)
//   const ref = useRef() as any
//   useOnClickOutside(ref, () => setShow(false))
//   return (
//     <div className='relative'>
//       <div
//         className='flex h-8 w-8 items-center justify-center rounded-md bg-primary'
//         onClick={() => setShow(!show)}
//       >
//         <Bars3Icon className='w-5 text-darkblue' />
//       </div>
//       <div
//         className={`absolute top-full right-0 ${show ? '' : 'hidden'}`}
//         ref={ref}
//       >
//         <ul className='mt-2 w-56 rounded-md bg-white'>
//           <li className='w-full px-5 py-3 hover:bg-primary'>
//             <Link to='marketplace' onClick={() => setShow(false)}>
//               MARKETPLACE
//             </Link>
//           </li>
//         </ul>
//       </div>
//     </div>
//   )
// }

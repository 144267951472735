const airdrop = [
  {
    id: '34i2ur5hnk34jr54352',
    name: 'zkSync Era Airdrop Round 1',
    description: {
      title: 'Rules:',
      content: [
        'Each NFT Era Ticket serves as one entry to the zkSync retroactive pool.',
        'Users can purchase multiple tickets with no restrictions. The more tickets owned, the higher the allocation of distributed rewards they may get.',
        <p>
          <span className='font-bold text-emerald-500'>Succeed: </span> If the campaign succeeds, users will receive 70% of the total airdrop rewards (distributed in the form of zkSync's Token).
        </p>,
        <p>
          <span className='font-bold text-redorange'>Fail: </span> If the campaign is not qualified for the airdrop, users will receive a refund based on the value of the guaranteed NFT.
        </p>,
        <p className="font-bold">In both cases, Era Protocol ensures a refund for users ($14/Ticket).</p>,
        'The Retroactive Pool will be divided into three separated rounds with different prices.',
        <div>
          <span className='font-bold'>Round 1:</span>
          <br />
          Time: 3AM UTC on June 15
          <br />
          NFT Era Ticket Price: 20 USD
          <br />
          Refund amount: 14 USD
        </div>,
        <div>
          <span className='font-bold'>Round 2:</span>
          <br /> Time: To be announced after Round 1 ends
          <br />
          NFT Era Ticket Price: 25 USD
          <br />
          Refund amount: 14 USD
        </div>,
        <div>
          <span className='font-bold'>Round 3:</span>
          <br /> Time: To be announced after Round 2 ends
          <br />
          NFT Era Ticket Price: 30 USD
          <br />
          Refund amount: 14 USD
        </div>,
        'Note: Each round will automatically conclude either when the specified time ends or when the total prize pool is reached!'
      ]
    },
    price: 20,
    symbol: 'https://rofi-game-01.b-cdn.net/eragames/EraGames-ticket-128.jpeg',
    currency: '10000 USD',
    from: '2023-04-10T00:00:00.000Z',
    to: '2023-04-11T00:00:00.000Z',
    total: 500,
    amount: 500,
    isComingSoon: false
  },
  {
    id: '34i2ur5hnk34jr54351',
    name: 'zkSync Era Airdrop Round 2 (Opening Soon)',
    description: {
      title: 'Rules:',
      content: [
        'Each NFT Era Ticket serves as one entry to the zkSync retroactive pool.',
        'Users can purchase multiple tickets with no restrictions. The more tickets owned, the higher the allocation of distributed rewards they may get.',
        <p>
          <span className='font-bold text-emerald-500'>Succeed: </span> If the campaign succeeds, users will receive 70% of the total airdrop rewards (distributed in the form of zkSync's Token).
        </p>,
        <p>
          <span className='font-bold text-redorange'>Fail: </span> If the campaign is not qualified for the airdrop, users will receive a refund based on the value of the guaranteed NFT.
        </p>,
        <p className="font-bold">In both cases, Era Protocol ensures a refund for users ($14/Ticket).</p>,
        'The Retroactive Pool will be divided into three separated rounds with different prices.',
        <div>
          <span className='font-bold'>Round 1:</span>
          <br />
          Time: 3AM UTC on June 15
          <br />
          NFT Era Ticket Price: 20 USD
          <br />
          Refund amount: 14 USD
        </div>,
        <div>
          <span className='font-bold'>Round 2:</span>
          <br /> Time: To be announced after Round 1 ends
          <br />
          NFT Era Ticket Price: 25 USD
          <br />
          Refund amount: 14 USD
        </div>,
        <div>
          <span className='font-bold'>Round 3:</span>
          <br /> Time: To be announced after Round 2 ends
          <br />
          NFT Era Ticket Price: 30 USD
          <br />
          Refund amount: 14 USD
        </div>,
        'Note: Each round will automatically conclude either when the specified time ends or when the total prize pool is reached!'
      ]
    },
    price: 25,
    symbol: 'https://rofi-game-01.b-cdn.net/eragames/EraGames-ticket-128.jpeg',
    currency: '~10000 USD',
    from: '2023-04-10T00:00:00.000Z',
    to: '2023-04-11T00:00:00.000Z',
    total: 0,
    amount: 0,
    isComingSoon: true
  },
  {
    id: '34i2ur5hnk34jr5435',
    name: 'zkSync Era Airdrop Round 3 (Opening Soon)',
    description: {
      title: 'Rules:',
      content: [
        'Each NFT Era Ticket serves as one entry to the zkSync retroactive pool.',
        'Users can purchase multiple tickets with no restrictions. The more tickets owned, the higher the allocation of distributed rewards they may get.',
        <p>
          <span className='font-bold text-emerald-500'>Succeed: </span> If the campaign succeeds, users will receive 70% of the total airdrop rewards (distributed in the form of zkSync's Token).
        </p>,
        <p>
          <span className='font-bold text-redorange'>Fail: </span> If the campaign is not qualified for the airdrop, users will receive a refund based on the value of the guaranteed NFT.
        </p>,
        <p className="font-bold">In both cases, Era Protocol ensures a refund for users ($14/Ticket).</p>,
        'The Retroactive Pool will be divided into three separated rounds with different prices.',
        <div>
          <span className='font-bold'>Round 1:</span>
          <br />
          Time: 3AM UTC on June 15
          <br />
          NFT Era Ticket Price: 20 USD
          <br />
          Refund amount: 14 USD
        </div>,
        <div>
          <span className='font-bold'>Round 2:</span>
          <br /> Time: To be announced after Round 1 ends
          <br />
          NFT Era Ticket Price: 25 USD
          <br />
          Refund amount: 14 USD
        </div>,
        <div>
          <span className='font-bold'>Round 3:</span>
          <br /> Time: To be announced after Round 2 ends
          <br />
          NFT Era Ticket Price: 30 USD
          <br />
          Refund amount: 14 USD
        </div>,
        'Note: Each round will automatically conclude either when the specified time ends or when the total prize pool is reached!'
      ]
    },
    price: 30,
    symbol: 'https://rofi-game-01.b-cdn.net/eragames/EraGames-ticket-128.jpeg',
    currency: 'USD',
    from: '2023-04-10T00:00:00.000Z',
    to: '2023-04-11T00:00:00.000Z',
    total: 0,
    amount: 0,
    isComingSoon: true
  }
]

export default airdrop

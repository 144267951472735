import { Ether, Token, WETH9 } from '@uniswap/sdk-core'
import { BUSD_ADDRESS, UNI_ADDRESS } from './addresses'
import { SupportedChainId } from './chains'

export const UNI: { [chainId: number]: Token } = {
	[SupportedChainId.BSC]: new Token(
		SupportedChainId.BSC,
		UNI_ADDRESS[56],
		18,
		'UNI',
		'Uniswap'
	)
}

export const LZ: { [chainId: number]: Token } = {
	[SupportedChainId.BSC]: new Token(
		SupportedChainId.BSC,
		BUSD_ADDRESS[56],
		18,
		'BUSD',
		'BUSD'
	)
}

export const WETH9_EXTENDED: { [chainId: number]: Token } = {
	...WETH9,
	[SupportedChainId.BSC]: new Token(
		SupportedChainId.BSC,
		'0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
		18,
		'BNB',
		'Wrapped BNB'
	)
}

export const ROFI_TOKEN: { [chainId: number]: Token } = {
	[SupportedChainId.BSC]: new Token(
		SupportedChainId.BSC,
		process.env.REACT_APP_ROFI_TOKEN_ADDRESS ||
			'0x3244B3B6030f374bAFA5F8f80Ec2F06aAf104B64',
		18,
		'ROFI',
		'ROFI'
	)
}

export const RG02_TOKEN_ADDRESS: { [chainId: number]: Token } = {
	[SupportedChainId.BSC]: new Token(
		SupportedChainId.BSC,
		'0xcA7124398CF5ECd13522b3019c244279F4D907DB',
		18,
		'IDL',
		'IDL'
	)
}

export const RG02_LP_TOKEN_ADDRESS: { [chainId: number]: Token } = {
	[SupportedChainId.BSC]: new Token(
		SupportedChainId.BSC,
		'0xcA7124398CF5ECd13522b3019c244279F4D907DB',
		18,
		'LP IDL',
		'LP IDL'
	)
}

export const USDT_ARB_ADDRESS: { [chainId: number]: Token } = {
	[SupportedChainId.ARBITRUM_ONE]: new Token(
		SupportedChainId.ARBITRUM_ONE,
		'0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
		18,
		'USDT',
		'USDT ARB'
	)
}

export const ERA_TOKEN_ADDRESS: { [chainId: number]: Token } = {
	[SupportedChainId.ARBITRUM_ONE]: new Token(
		SupportedChainId.ARBITRUM_ONE,
		'0x8Cd58EA08a57ac7C4887674AA96c8B782Da6978A',
		18,
		'ERA',
		'ERA TOKEN'
	)
}

export class ExtendedEther extends Ether {
	public get wrapped(): Token {
		if (this.chainId in WETH9_EXTENDED) return WETH9_EXTENDED[this.chainId]
		throw new Error('Unsupported chain ID')
	}

	private static _cachedEther: { [chainId: number]: ExtendedEther } = {}

	public static onChain(chainId: number): ExtendedEther {
		return (
			this._cachedEther[chainId] ??
			(this._cachedEther[chainId] = new ExtendedEther(chainId))
		)
	}
}

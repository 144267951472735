import Countdown from 'components/Airdrop/Countdown'
import Header from 'components/Airdrop/Header'
import AirdropItem from 'components/Airdrop/Item/AirdropItem'
import airdrop from 'data/airdrop'
import { useState } from 'react'
import { AidropItem } from 'types/aidrop-item'

const AirDrop = () => {
  //? State
  const [data] = useState<Array<AidropItem>>(airdrop)

  return (
    <div
      className='mx-auto min-h-[calc(100vh-50px)] w-full space-y-[40px] px-[15px] pt-10 pb-20 md:space-y-[40px]
      lg:pt-20 lg:pb-10 
      xl:w-[calc(100%-18rem)] 
      2xl:w-[calc(100%-20rem)]'
      key={'airdrop-container'}
    >
      <Header />

      <Countdown />

      <div className=''>
        <ul className='space-y-2.5'>
          {data.map((item) => (
            <li key={item.id}>
              <AirdropItem data={item} />
            </li>
          ))}
        </ul>
      </div>
      {/* <DefaultModal title={'Popup Title'} visible={true} onClose={() => {}}>
        <p className=''>
          Inkbox are one of the largest impermanent tattoo brands globally, with
          over 2 million followers across their social channels. And licensed
          agreements with Disney, Warner Brothers, Stranger Things, Rick and
          Morty, Post Malone, BTS, Gorillas and many more.. Inkbox was recently
          acquired by it's parent company 'BIC' for $65 Million. The parent
          company BIC is a multi-Billion dollar company specialising in
          stationary. Starting in 2015 and since growing to over $400,000 USD
          weekly revenue, Inkbox now has a catalogue of more than 10,000 designs
          and ships tens of thousands of tattoos globally every week.
        </p>
        <div className='mt-[40px]'>
          <CustomButton>Click</CustomButton>
        </div>
      </DefaultModal> */}
    </div>
  )
}

export default AirDrop

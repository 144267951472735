import { useActiveWeb3React } from './web3'
import { USDT_ADDRESS } from '../constants/addresses'
import { useMemo } from 'react'
import { Token } from '@uniswap/sdk-core'

export default function useUSDTCurrency() {
  const { chainId } = useActiveWeb3React()
  if (!chainId) return undefined
  const address = USDT_ADDRESS[chainId]
  if (!address) return undefined
  return new Token(
    chainId || 42161,
    address,
    6,
    'USDT',
    'USDT ARB'
  )
}
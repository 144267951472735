export enum SupportedChainId {
  ARBITRUM_ONE = 42161,
  BSC = 56,
  zkSYNC_ERA = 324,
  ETHEREUM = 1,
  POLYGON = 137,
  OPTIMISM = 10,
}

export const NETWORK_LABELS: { [chainId in SupportedChainId | number]: string } = {
  [SupportedChainId.BSC]: 'BSC Mainnet',
  [SupportedChainId.ARBITRUM_ONE]: 'ARBITRUM ONE',
  [SupportedChainId.zkSYNC_ERA]: 'zkSync Era Mainnet',
  [SupportedChainId.ETHEREUM]: 'Ethereum',
  [SupportedChainId.POLYGON]: 'Polygon',
  [SupportedChainId.OPTIMISM]: 'Optimism',
}

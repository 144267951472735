import AIRDROP_POOL_V1_ABI from 'abis/eragames/airdrop-pool-v1.json'
import { Tooltip } from 'antd'
import airdropApi from 'api/airdrop'
import { AIRDROP_POOL_V1_ADDRESS } from 'constants/addresses'
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion'
import { useActiveWeb3React } from 'hooks/web3'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AidropItem } from 'types/aidrop-item'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import Detail from './Detail'
import Overview from './Overview'

const getTimeRemaining = (endtime: any) => {
  const total = Date.parse(endtime) - Date.parse(`${new Date()}`)
  const seconds = Math.floor((total / 1000) % 60)
  const minutes = Math.floor((total / 1000 / 60) % 60)
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
  const days = Math.floor(total / (1000 * 60 * 60 * 24))

  return {
    total,
    days,
    hours,
    minutes,
    seconds
  }
}

const AirdropItem = ({ data }: { data: AidropItem }) => {
  const { chainId } = useActiveWeb3React()

  const [spread, setSpread] = useState(false)

  const [dataState, setDataState] = useState<AidropItem>(data)

  const web3 = useMemo(() => new Web3('https://arb1.arbitrum.io/rpc'), [])

  const airdropPoolCFT = useMemo(() => {
    if (!AIRDROP_POOL_V1_ADDRESS[chainId || 42161]) return undefined
    return new web3.eth.Contract(
      AIRDROP_POOL_V1_ABI as AbiItem[],
      AIRDROP_POOL_V1_ADDRESS[chainId || 42161]
    )
  }, [chainId, web3.eth.Contract])

  const updatePoolInformation = useCallback(async () => {
    try {
      const result = await airdropApi.updatePoolInfo()
      const data = result.data
      const sold = data.total
      setDataState((prev) => ({
        ...prev,
        amount: prev.total - sold
      }))
      return
    } catch (error: any) {
      console.error('🤯 Update Pool Error:', error)
    }
  }, [])

  const detailRef = useRef(null) as any

  const time = useMemo(() => {
    return `${getTimeRemaining(data.to).minutes} hours left`
  }, [data.to])

  useEffect(() => {
    spread &&
      detailRef?.current &&
      detailRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
  }, [spread])

  useEffect(() => {
    updatePoolInformation()
    const interval = setInterval(() => updatePoolInformation(), 15000)
    return () => {
      clearInterval(interval)
    }
  }, [updatePoolInformation])

  return (
    <div className='p-1'>
      <motion.div
        className={`rounded-5 ring-2 ring-offset-2 ring-offset-tw-500
        transition-all duration-200 ease-linear
        ${
          spread
            ? 'ring-redorange hover:ring-redorange'
            : 'ring-tw-200 hover:ring-highlight'
        }`}
        ref={detailRef}
      >
        <Tooltip title='Click to view detail'>
          <Overview
            key={`😩-airdrop-item`}
            spread={spread}
            name={data.name}
            symbol={data.symbol}
            currency={data.currency}
            total={data.total}
            amount={dataState.amount}
            time={time}
            price={data.price}
            isComingSoon={data.isComingSoon}
            onClick={() => {
              setSpread(!spread)
            }}
          />
        </Tooltip>
        <AnimatePresence exitBeforeEnter>
          {spread ? (
            <div className=''>
              <Detail
                key={`😪-airdrop-item-`}
                symbol={data.symbol}
                description={data.description}
                total={data.total}
                price={data.price}
                amount={dataState.amount}
                spread={spread}
                isComingSoon={data.isComingSoon}
                onSpread={() => setSpread(!spread)}
                onUpdate={updatePoolInformation}
              />
            </div>
          ) : null}
        </AnimatePresence>
      </motion.div>
    </div>
  )
}

export default AirdropItem

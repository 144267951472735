import { ROFI_ACCOUNT, ROFI_NFT } from 'constants/endpointConfigs'
import axiosClient from './http-common'

type SetupAccountType = {
  username: string
  password: string
}

const userApi = {
  getInfo: (): Promise<any> => {
    const url = ROFI_ACCOUNT.INFO
    return axiosClient.get(url)
  },
  getProfile: (): Promise<any> => {
    const url = ROFI_ACCOUNT.PROFILE
    return axiosClient.get(url)
  },
  updateProfile: (data: any): Promise<any> => {
    const url = ROFI_ACCOUNT.PROFILE
    return axiosClient.post(url, data)
  },
  removeAccount: (): Promise<any> => {
    const url = ROFI_ACCOUNT.REMOVE
    return axiosClient.delete(url)
  },
  getSignatureFromWallet: (message: string): Promise<any> => {
    const url = ROFI_ACCOUNT.AUTH_WALLET
    return axiosClient.post(url, { token: message })
  },
  setupAccount: (data: SetupAccountType): Promise<any> => {
    const url = ROFI_ACCOUNT.SETUP_ACCOUNT
    return axiosClient.post(url, { ...data })
  },
  setupEmail: (email: string): Promise<any> => {
    const url = ROFI_ACCOUNT.SETUP_EMAIL_OTP
    return axiosClient.post(url, { email })
  },
  verifyOTPSetupEmail: (email: string, otp: string | number): Promise<any> => {
    const url = ROFI_ACCOUNT.SETUP_EMAIL_VERIFY
    return axiosClient.post(url, { email, otp })
  },
  getInventory: (params = {}): Promise<any> => {
    const url = ROFI_NFT.INVENTORY
    return axiosClient.get(url, { params })
  },
  getInventoryItem: (id = ''): Promise<any> => {
    const url = `${ROFI_NFT.ITEM}/${id}`
    return axiosClient.get(url)
  },

  revokeApple: (rfToken: string): Promise<any> => {
    const url = ROFI_ACCOUNT.REVOKE_APPLE
    return axiosClient.post(url, { refreshToken: rfToken })
  }
}

export default userApi

import Introduce from './Introduce'

const Header = () => {
  return (
    <div
      className='flex flex-col gap-2.5 border-b border-b-border01 pb-15px
      sm:gap-10 lg:flex-row'
    >
      <div className='text-center lg:min-w-[400px] lg:text-left'>
        <h1 className='font-bold'>Airdrop Pool</h1>
        <p className='mt-[5px] text-sm font-light'>Retroactive as service!</p>
      </div>
      <Introduce />
    </div>
  )
}

export default Header

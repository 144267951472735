import { XMarkIcon } from '@heroicons/react/24/solid'
import { Modal } from 'antd'
import { FC } from 'react'
import styled from 'styled-components'

interface IProps {
  visible: boolean
  title: string
  className?: string
  allowClose?: boolean
  onClose?: () => void
}

const ModalStyle = styled(Modal)`
  ${({ theme }) => theme.mediaWidth.upTo768`
      max-width: calc(100vw - 20px);
  `};
  .ant-modal-body {
    padding: 30px;
    ${({ theme }) => theme.mediaWidth.upTo1200`
      padding: 30px;
    `};
  }
  .ant-modal-content {
    background: #1a1a1a;
    border: none;
    box-shadow: none;
    border-radius: 5px;

    .ant-modal-close {
      top: 5px;
      right: 5px;
      .ant-modal-close-x {
        width: 34px;
        height: 34px;
        .ant-modal-close-icon {
          display: none;
        }
      }
    }
  }
`

const DefaultModal: FC<IProps> = ({
  title,
  children,
  visible,
  onClose,
  allowClose = true,
  className
}) => {
  return (
    <ModalStyle
      footer={null}
      visible={visible}
      centered
      closeIcon={
        allowClose ? (
          <div className='w-max'>
            <XMarkIcon
              className='!mr-0 w-[34px] rounded-5 text-white transition-all 
						duration-200 ease-linear hover:bg-tw-300'
            />
          </div>
        ) : null
      }
      className={`!w-[845px] rounded-5 outline outline-offset-2 
			outline-tw-200 ${className}`}
      onCancel={() => onClose && onClose()}
    >
      <div
        className='mb-[30px] text-center font-sans text-[28px] font-medium
				leading-[34px] text-white md:text-[38px] md:leading-[42px]'
      >
        {title}
      </div>
      <div className='font-sans text-base font-normal text-textcolor'>
        {children}
      </div>
    </ModalStyle>
  )
}

export default DefaultModal

import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'
import walletApi from 'api/walletApi'

type ArgsFetchBalance = {
  address: string
  symbol: string
}

const voidInit = () => {}

export const returnToInitialWallet = createAction('wallets/returnToInitial')

export const fetchAllBalances = createAsyncThunk(
  'wallets/getAllBalances',
  async () => {
    const result = await walletApi.getAllBalances()
    return result
  }
)

export const fetchBalance = createAsyncThunk(
  'wallets/getBalance',
  async ({ address, symbol }: ArgsFetchBalance) => {
    const result: any = await walletApi.getBalance(address, symbol)
    return { data: result?.data, symbol }
  }
)

export const actionFetchAllBalances = (
  onSuccess = voidInit,
  onError = voidInit
) => {
  return async (dispatch: any) => {
    try {
      const resultAction = await dispatch(fetchAllBalances())
      unwrapResult(resultAction)
      onSuccess()
    } catch (rejectedValueOrSerializedError) {
      onError()
    }
  }
}

export const actionFetchBalance = (
  data: ArgsFetchBalance,
  onSuccess = voidInit,
  onError = voidInit
) => {
  return async (dispatch: any) => {
    try {
      const resultAction = await dispatch(fetchBalance(data))
      unwrapResult(resultAction)
      onSuccess()
    } catch (rejectedValueOrSerializedError) {
      onError()
    }
  }
}

import { createSlice, current } from '@reduxjs/toolkit'
import { fetchAllBalances, fetchBalance, returnToInitialWallet } from './action'

const initialState: {
  loading: boolean
  error: any
  wallet: Array<any>
} = {
  loading: false,
  error: null,
  wallet: [
    {
      symbol: 'IDL',
      balance: 0
    },
    { symbol: 'ROFI', balance: 0 }
  ]
}

export const walletSlice = createSlice({
  name: 'wallets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(returnToInitialWallet, (state) => {
      state.wallet = initialState.wallet
    })

    builder
      .addCase(fetchAllBalances.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAllBalances.fulfilled, (state, { payload }) => {
        state.loading = false
        state.wallet = current(state).wallet.map((item: any) => {
          const syb = item.symbol
          const newItem = { ...item }
          for (let iResult of payload.data) {
            if (iResult.symbol === syb) {
              newItem.balance = iResult.balance
              break
            }
          }
          return newItem
        })
      })
      .addCase(fetchAllBalances.rejected, (state, { error }) => {
        state.loading = false
        state.error = error
      })

    builder
      .addCase(fetchBalance.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchBalance.fulfilled, (state, { payload }) => {
        state.loading = false
        state.wallet = current(state).wallet.map((item) => {
          let newItem = { ...item }
          if (newItem.symbol === payload.symbol) newItem.balance = payload.data
          return newItem
        })
      })
      .addCase(fetchBalance.rejected, (state, { error }) => {
        state.loading = false
        state.error = error
      })
  }
})

export const walletReducer = walletSlice.reducer

import { Steps } from 'antd'
import DefaultModal from './DefaultModal'

interface ITransactionStep {
  visible: boolean
  step: number
}

const { Step } = Steps

const TransactionStepModal = ({ visible, step = 1 }: ITransactionStep) => {
  return (
    <DefaultModal
      title='Transaction progress'
      visible={visible}
      className='!w-max'
      allowClose={false}
    >
      <div className='mx-auto w-full max-w-[215px]'>
        <Steps
          direction='vertical'
          size='small'
          current={step}
          status={'process'}
        >
          <Step title='Interactive contract' />
          <Step title='Confirm on wallet' />
          <Step title='Transaction summited' />
          <Step title='Transaction confirmed' />
          <Step title='Success' />
        </Steps>
      </div>
    </DefaultModal>
  )
}

export default TransactionStepModal

import { createSlice } from '@reduxjs/toolkit'
import { fetchConfig } from './actions'

const initialState: {
  loading: boolean
  error: any
  config: any
} = {
  loading: false,
  error: null,
  config: {
    authentication: {
      signin: {
        methods: {
          usernamePassword: true,
          usernameOtp: true,
          socials: []
        }
      }
    },
    payment: {
      methods: ['crypto', 'h2p', 'Xsolla']
    },
    store: {
      statistic: {
        display: false
      },
      default_currency_symbol: ''
    },
    marketplace: {
      statistic: {
        display: false
      }
    },
    top_nav: {
      item: {}
    },
    general: {
      defaultPage: '/airdrop',
      logoUrl: ''
    }
  }
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfig.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchConfig.fulfilled, (state, { payload }) => {
        state.loading = false
        state.config = payload
      })
      .addCase(fetchConfig.rejected, (state, { error }) => {
        state.loading = false
        state.error = error
      })
  }
})

export const globalReducer = globalSlice.reducer

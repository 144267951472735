import { Button, Tooltip } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import React, { forwardRef } from 'react'
type Props = {
  className?: string
  size?: SizeType
  onClick?: () => void
  loading?: boolean
  disable?: boolean
  children?: React.ReactNode | string
  tooltip?: React.ReactNode | string
  type?: any
}

const ButtonPrimary = forwardRef(
  (
    {
      className = '',
      size,
      onClick,
      loading = false,
      disable = false,
      children,
      tooltip = '',
      type = ''
    }: Props,
    ref: any
  ) => {
    return (
      <Tooltip placement='top' title={tooltip}>
        <Button
          type='primary'
          className={`btn-gray ${className} !disabled:bg-opacity-75`}
          loading={loading}
          onClick={() => !disable && onClick && onClick()}
          size={size}
          disabled={disable}
          htmlType={type}
          ref={ref}
        >
          {children}
        </Button>
      </Tooltip>
    )
  }
)

export { ButtonPrimary }

const new_network_config: any = {
  1: {
    chainName: 'Ethereum',
    chainId: 1,
    nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
    rpcUrls: ['https://rpc.ankr.com/eth']
  },
  56: {
    chainName: 'BNB Smart Chain',
    chainId: 56,
    nativeCurrency: { name: 'BNB', decimals: 18, symbol: 'BNB' },
    rpcUrls: ['https://bsc-dataseed.binance.org']
  },
  42161: {
    chainName: 'Arbitrum One',
    chainId: 42161,
    nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
    rpcUrls: ['https://arbitrum-one.publicnode.com']
  },
  324: {
    chainName: 'zkSync Era Mainnet',
    chainId: 324,
    nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
    rpcUrls: ['https://mainnet.era.zksync.io']
  }
}
export default new_network_config

import { Redirect, RouteComponentProps } from 'react-router-dom'
import { useGlobalConfigSelector } from 'state/global/hooks'

// Redirects to marketplace but only replace the pathname
export function RedireactToDefaultPage({ location }: RouteComponentProps) {
  const { config } = useGlobalConfigSelector()

  return (
    <Redirect
      to={{
        ...location,
        pathname: config.general.defaultPage
      }}
    />
  )
}

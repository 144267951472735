import { AIRDROP_POOL_SERVICE } from 'constants/endpointConfigs'
import axiosClient from './http-common'

const airdropApi = {
  getUserInfo: (address: string): Promise<any> => {
    const url = AIRDROP_POOL_SERVICE.USER_INFO(address)
    return axiosClient.get(url)
  },
  updatePoolInfo: (): Promise<any> => {
    const url = AIRDROP_POOL_SERVICE.UPDATE_POOL_INFO
    return axiosClient.get(url)
  }
}

export default airdropApi

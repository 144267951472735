import { FC, createContext, useContext, useState } from 'react'

const GlobalContext = createContext(null) as any

const GlobalProvider: FC = ({ children }): JSX.Element => {
  const [isWrongNetwork, setIsWrongNetwork] = useState(false)

  const openWrongNetwork = () => {
    setIsWrongNetwork(true)
  }
  const closeWrongNetwork = () => {
    setIsWrongNetwork(false)
  }

  return (
    <GlobalContext.Provider
      value={{ isWrongNetwork, openWrongNetwork, closeWrongNetwork }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

const useGlobalContext = () => useContext(GlobalContext)

export { useGlobalContext, GlobalProvider }

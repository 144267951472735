import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { load, save } from 'redux-localstorage-simple'

import application from './application/reducer'
import { authReducer } from './auth/reducer'
import connector from './connector/reducer'
import { updateVersion } from './global/actions'
import { globalReducer } from './global/reducer'
import lists from './lists/reducer'
import lotteryReducer from './lottery'
import multicall from './multicall/reducer'
import nftReducer from './nft'
import rofi from './rofi/reducer'
import { serviceReducer } from './service/reducer'
import transactions from './transactions/reducer'
import user from './user/reducer'
import { walletReducer } from './wallet/reducer'

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'lists']

const store = configureStore({
  reducer: {
    application,
    user,
    transactions,
    multicall,
    lists,
    order: lotteryReducer,
    nft: nftReducer,
    rofi,
    wallet: walletReducer,
    service: serviceReducer,
    auth: authReducer,
    connector: connector,
    global: globalReducer
  },
  middleware: [
    ...getDefaultMiddleware({ thunk: true }),
    save({ states: PERSISTED_KEYS, debounce: 1000 })
  ],
  preloadedState: load({ states: PERSISTED_KEYS })
})

store.dispatch(updateVersion())

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

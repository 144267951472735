import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import Option from 'components/LoginModal/Option'
import { SUPPORTED_WALLETS } from 'constants/wallet'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import ReactGA from 'react-ga'

import { AbstractConnector } from '@web3-react/abstract-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { injected } from 'connectors'

import { BloctoConnector } from '@blocto/blocto-connector'
import { Trans } from '@lingui/macro'
import MetamaskIcon from 'assets/rofi-games/login/metamask.png'
import { useWalletConnector } from 'state/connector/hooks'

const WALLET_VIEWS = {
  OPTIONS: 'options',
  OPTIONS_SECONDARY: 'options_secondary',
  ACCOUNT: 'account',
  PENDING: 'pending'
}

export const ConnectWalletOptions = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  const { connector, activate, account } = useWeb3React()
  const walletConnector = useWalletConnector()

  //? State
  const [pendingWallet, setPendingWallet] = useState<
    AbstractConnector | undefined
  >()
  const [pendingError, setPendingError] = useState<boolean>()
  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)

  const setWalletConnector = (connector: any) => {
    if (connector instanceof WalletConnectConnector) {
      walletConnector('walletconnect')
      localStorage.setItem('walletConnector', 'walletconnect')
      return
    }
    if (connector instanceof BloctoConnector) {
      walletConnector('blocto')
      localStorage.setItem('walletConnector', 'blocto')
      return
    }

    walletConnector('injected')
    localStorage.setItem('walletConnector', 'injected')
    return
  }

  const tryActivation = async (connector: AbstractConnector | undefined) => {
    let name = ''
    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        return (name = SUPPORTED_WALLETS[key].name)
      }
      return true
    })
    // log selected wallet
    ReactGA.event({
      category: 'Wallet',
      action: 'Change Wallet',
      label: name
    })
    setPendingWallet(connector) // set wallet for pending view
    setWalletView(WALLET_VIEWS.PENDING)

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (
      connector instanceof WalletConnectConnector &&
      connector.walletConnectProvider?.wc?.uri
    ) {
      connector.walletConnectProvider = undefined
    }

    connector &&
      activate(connector, undefined, true)
        .catch((error) => {
          if (error instanceof UnsupportedChainIdError) {
            activate(connector) // a little janky...can't use setError because the connector isn't set
          } else {
            setPendingError(true)
          }
        })
        .then(() => {
          setWalletConnector(connector)
          localStorage.setItem('isAuthorized', 'true')
          onSuccess()
        })
  }

  const isMetamask = window.ethereum && window.ethereum.isMetaMask

  return (
    <>
      {Object.keys(SUPPORTED_WALLETS).map((key) => {
        const option = SUPPORTED_WALLETS[key]
        // check for mobile options
        if (isMobile) {
          if (!isMetamask && option.name === 'MetaMask') return null
          return (
            <Option
              onClick={() => {
                option.connector !== connector &&
                  !option.href &&
                  tryActivation(option.connector)
              }}
              id={`connect-${key}`}
              key={key}
              active={option.connector && option.connector === connector}
              color={option.color}
              link={option.href}
              header={option.name}
              subheader={null}
              icon={option.iconURL}
              className={`p-1`}
            />
          )
        }

        // overwrite injected when needed
        if (option.connector === injected) {
          // don't show injected if there's no injected provider
          if (!(window.web3 || window.ethereum)) {
            if (option.name === 'MetaMask') {
              return (
                <Option
                  size={26}
                  id={`connect-${key}`}
                  key={key}
                  color={'#E8831D'}
                  header={<Trans>Install Metamask</Trans>}
                  subheader={null}
                  // link={'https://metamask.io/'}
                  icon={MetamaskIcon}
                  onClick={() => {
                    window.open('https://metamask.io/', '_blank')
                  }}
                  className={`p-1`}
                />
              )
            } else {
              return null //dont want to return install twice
            }
          }
          // don't return metamask if injected provider isn't metamask
          else if (option.name === 'MetaMask' && !isMetamask) {
            return null
          }
          // likewise for generic
          else if (option.name === 'Injected' && isMetamask) {
            return null
          }
        }

        // return rest of options
        return (
          !isMobile &&
          !option.mobileOnly && (
            <Option
              id={`connect-${key}`}
              onClick={() => {
                option.connector === connector
                  ? setWalletView(WALLET_VIEWS.ACCOUNT)
                  : !option.href && tryActivation(option.connector)
              }}
              key={key}
              active={option.connector === connector}
              color={option.color}
              link={option.href}
              header={option.name}
              subheader={null} //use option.description to bring back multi-line
              icon={option.iconURL}
              className={`p-1`}
            />
          )
        )
      })}
    </>
  )
}

class TokenService {
  getLocalRefreshToken() {
    return localStorage.getItem('x-rf-token')
  }

  getLocalAccessToken() {
    return localStorage.getItem('token_api')
  }

  updateLocalAccessToken(token: string) {
    let result = localStorage.getItem('token_api')
    result = token
    localStorage.setItem('token_api', result)
  }

  setLocalRefreshToken(token: string) {
    localStorage.setItem('x-rf-token', token)
  }

  setLocalAccessToken(token: string) {
    localStorage.setItem('token_api', token)
  }

  setExpired(value: any) {
    localStorage.setItem('expireInToken', value)
  }

  removeToken() {
    localStorage.removeItem('token_api')
    localStorage.removeItem('x-rf-token')
    localStorage.removeItem('expireInToken')
  }
}

export default new TokenService()

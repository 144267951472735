import { InformationCircleIcon } from '@heroicons/react/24/outline'

const Introduce = () => {
  return (
    <div
      className='flex gap-[13px] rounded-5 border-2 border-tw-300 
      bg-tw-700 py-5 px-5'
    >
      <div className='w-6 min-w-[24px]'>
        <InformationCircleIcon className='w-full text-highlight' />
      </div>
      <div className='text-md leading-[17px]'>
        <p style={{ padding: '5px 0' }}>
          <span className='font-bold'>zkSync Era</span> is a Layer-2 scaling solution developed by Matter Labs, aimed at reducing costs, speeding up transaction processing, and enhancing security on Ethereum by utilizing ZK Rollup technology.
          <a href='https://zksync.io/' target='_blank' rel='noopener noreferrer' className='font-bold px-1'>{' '}Read more</a>
        </p>
        <p style={{ padding: '5px 0' }}>
          To be early users of zkSync and win a chance of earning zkSync's token, the only way is to enter zkSync Era retroactive.
        </p>
        <p style={{ padding: '5px 0' }}>
          Start your first mission of getting retroactive reward token, buy the ticket below.
        </p>
      </div>
    </div>
  )
}

export default Introduce

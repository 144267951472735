import { ROFI_ACCOUNT } from 'constants/endpointConfigs'
import axiosClient from './http-common'

type TAuthenticationType = {
  account: string
  password: string
}

type TSignUpType = {
  username: string
  password: string
}

type TVerifyOTP = {
  email: string
  otp: string
}

const authApi = {
  authentication: (data: TAuthenticationType): Promise<any> => {
    const url = ROFI_ACCOUNT.AUTH
    return axiosClient.post(url, data)
  },
  sendOTPToEmailSignIn: (email: string): Promise<any> => {
    const url = ROFI_ACCOUNT.SIGNIN_EMAIL_OTP
    return axiosClient.post(url, { email })
  },
  verifyOTPSignIn: (data: TVerifyOTP): Promise<any> => {
    const url = ROFI_ACCOUNT.SIGNIN_EMAIL_VERIFY
    return axiosClient.post(url, data)
  },
  sendOTPToEmailSignUp: (email: string): Promise<any> => {
    const url = ROFI_ACCOUNT.SIGNUP_EMAIL_OTP
    return axiosClient.post(url, { email })
  },
  verifyOTPSignUp: (data: TVerifyOTP): Promise<any> => {
    const url = ROFI_ACCOUNT.SIGNUP_EMAIL_VERIFY
    return axiosClient.post(url, data)
  },
  signup: (data: TSignUpType): Promise<any> => {
    const url = ROFI_ACCOUNT.SETUP_ACCOUNT
    return axiosClient.post(url, data)
  },
  sendOTPForgotPassword: (account: string): Promise<any> => {
    const url = ROFI_ACCOUNT.RECOVERY_PASSWORD_EMAIL_OTP
    return axiosClient.post(url, { account })
  },
  resetPassword: (
    account: string,
    otp: string,
    password: string
  ): Promise<any> => {
    const url = ROFI_ACCOUNT.RECOVERY_PASSWORD_VALIDATE_OTP
    return axiosClient.post(url, {
      account,
      otp,
      newPassword: password
    })
  },
  appleToken: (code: string): Promise<any> => {
    const url = ROFI_ACCOUNT.GET_APPLE_TOKEN
    return axiosClient.post(url, { code })
  },
  appleAuth: (idToken: string): Promise<any> => {
    const url = ROFI_ACCOUNT.AUTH_APPLE
    return axiosClient.post(url, { idToken })
  },
  facebookAuth: (oauthToken: string): Promise<any> => {
    const url = ROFI_ACCOUNT.AUTH_FACEBOOK
    return axiosClient.post(url, { oauthToken })
  },
  googleAuth: (accessToken: string): Promise<any> => {
    const url = ROFI_ACCOUNT.AUTH_GOOGLE
    return axiosClient.post(url, { accessToken })
  }
}

export default authApi

import { Contract } from '@ethersproject/contracts'

import ENS_PUBLIC_RESOLVER_ABI from 'abis/ens-public-resolver.json'
import ENS_ABI from 'abis/ens-registrar.json'
import ERC20_ABI from 'abis/erc20.json'
import ERC20_BYTES32_ABI from 'abis/erc20_bytes32.json'
import ERC721_ABI from 'abis/erc721.json'
import MULTICALL_ABI from 'abis/multicall2.json'
import PANCAKE_PAIR_ABI from 'abis/pancake-pair.json'
import WETH_ABI from 'abis/weth.json'

import RG02_BOX_ABI from 'abis/dance/dance-box.json'
import RG02_NFT_ABI from 'abis/dance/dance-item-nft.json'
import RG02_MINT_NFT_ABI from 'abis/dance/dance-mint-nft.json'
import RG02_TRANSFER_NFT_ABI from 'abis/dance/dance-transfer-nft.json'
import RGO2_NFT_AIRDROP_ABI from 'abis/dance/rg02-nft-airdrop.json'
import RGO2_NFT_MARKET_ABI from 'abis/dance/rg02-nft-market.json'
import SHOP_NFT_ABI from 'abis/shop/shop-nft.json'
import SHOP_NON_NFT_ABI from 'abis/shop/shop-non-nft.json'
import TRANSFER_TOKEN_ABI from 'abis/wallet/transfer-token.json'
import RG02_UNLOCKED_STAKE_ABI from 'abis/earn/unlocked-stake.json'
import RG02_LOCKED_STAKE_ABI from 'abis/earn/locked-stake.json'
import RG02_UNLOCKED_POOL_REWARD_ABI from 'abis/earn/pool-reward.json'
import RG02_LOCKED_POOL_REWARD_ABI from 'abis/earn/pool-reward.json'
//ERAGAMES
import AIRDROP_POOL_V1_ABI from 'abis/eragames/airdrop-pool-v1.json'
import FAIR_AUCTION_ABI from 'abis/eragames/fair-auction.json'

import {
  ENS_REGISTRAR_ADDRESSES,
  MULTICALL2_ADDRESSES,
  RG02_BOX_ADDRESS,
  RG02_IDL_SHOP_ADDRESS,
  RG02_MINT_NFT_ADDRESS,
  RG02_NFT_ADDRESS,
  RG02_NFT_AIRDROP_ADDRESS,
  RG02_NFT_MARKET_ADDRESS,
  RG02_NFT_SHOP_ADDRESS,
  RG02_TRANSFER_NFT_ADDRESS,
  TRANSFER_TOKEN_ADDRESS,
  RG02_UNLOCKED_STAKE_ADDRESS,
  RG02_LOCKED_STAKE_ADDRESS,
  RG02_UNLOCKED_POOL_REWARD_ADDRESS,
  RG02_LOCKED_POOL_REWARD_ADDRESS,
  //ERAGAMES
  AIRDROP_POOL_V1_ADDRESS,
  FAIR_AUCTION_ADDRESS,
} from 'constants/addresses'
import { useMemo } from 'react'
import { getContract } from 'utils'
import {
  EnsPublicResolver,
  EnsRegistrar,
  Erc20,
  Multicall2,
  Weth
} from '../abis/types'
import { WETH9_EXTENDED } from '../constants/tokens'
import { useActiveWeb3React } from './web3'

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined
      )
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [
    addressOrAddressMap,
    ABI,
    library,
    chainId,
    withSignerIfPossible,
    account
  ]) as T
}

export function useTokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useERC721TokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(tokenAddress, ERC721_ABI, withSignerIfPossible)
}

export function usePancakePairContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(tokenAddress, PANCAKE_PAIR_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean) {
  const { chainId } = useActiveWeb3React()
  return useContract<Weth>(
    chainId ? WETH9_EXTENDED[chainId]?.address : undefined,
    WETH_ABI,
    withSignerIfPossible
  )
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean) {
  return useContract<EnsRegistrar>(
    ENS_REGISTRAR_ADDRESSES,
    ENS_ABI,
    withSignerIfPossible
  )
}

export function useENSResolverContract(
  address: string | undefined,
  withSignerIfPossible?: boolean
) {
  return useContract<EnsPublicResolver>(
    address,
    ENS_PUBLIC_RESOLVER_ABI,
    withSignerIfPossible
  )
}

export function useBytes32TokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useMulticall2Contract() {
  return useContract<Multicall2>(
    MULTICALL2_ADDRESSES,
    MULTICALL_ABI,
    false
  ) as Multicall2
}

//PE

export function useDanceNFTContract(): Contract | null {
  return useContract(RG02_NFT_ADDRESS, RG02_NFT_ABI, true)
}

export function useDanceBoxContract(): Contract | null {
  return useContract(RG02_BOX_ADDRESS, RG02_BOX_ABI, true)
}

export function useDanceTransferTokenContract(): Contract | null {
  return useContract(TRANSFER_TOKEN_ADDRESS, TRANSFER_TOKEN_ABI, true)
}

export function useDanceTransferNftContract(): Contract | null {
  return useContract(RG02_TRANSFER_NFT_ADDRESS, RG02_TRANSFER_NFT_ABI, true)
}

export function useIDLMintNftContract(): Contract | null {
  return useContract(RG02_MINT_NFT_ADDRESS, RG02_MINT_NFT_ABI, true)
}

export function useRofiTransferTokenContract(): Contract | null {
  return useContract(TRANSFER_TOKEN_ADDRESS, TRANSFER_TOKEN_ABI, true)
}

export function useDanceMarketNftContract(): Contract | null {
  return useContract(RG02_NFT_MARKET_ADDRESS, RGO2_NFT_MARKET_ABI, true)
}

export function useNftShopNftContract(): Contract | null {
  return useContract(RG02_NFT_SHOP_ADDRESS, SHOP_NFT_ABI, true)
}

export function useNftShopNonNftContract(): Contract | null {
  return useContract(RG02_IDL_SHOP_ADDRESS, SHOP_NON_NFT_ABI, true)
}

export function useIdolWorldAirdropContract(): Contract | null {
  return useContract(RG02_NFT_AIRDROP_ADDRESS, RGO2_NFT_AIRDROP_ABI, true)
}

export function useUnlockedStakeContract(): Contract | null {
  return useContract(RG02_UNLOCKED_STAKE_ADDRESS, RG02_UNLOCKED_STAKE_ABI, true)
}

export function useLockedStakeContract(): Contract | null {
  return useContract(RG02_LOCKED_STAKE_ADDRESS, RG02_LOCKED_STAKE_ABI, true)
}

export function useUnlockedPoolRewardContract(): Contract | null {
  return useContract(RG02_UNLOCKED_POOL_REWARD_ADDRESS, RG02_UNLOCKED_POOL_REWARD_ABI, true)
}

export function useLockedPoolRewardContract(): Contract | null {
  return useContract(RG02_LOCKED_POOL_REWARD_ADDRESS, RG02_LOCKED_POOL_REWARD_ABI, true)
}


//ERAGAMES
export function useAirdropPoolV1Contract(): Contract | null {
  return useContract(AIRDROP_POOL_V1_ADDRESS, AIRDROP_POOL_V1_ABI, true)
}

export function useFairAuctionContract(): Contract | null {
  return useContract(FAIR_AUCTION_ADDRESS, FAIR_AUCTION_ABI, true)
}
